// src/utils/FallbackSpeechRecognition.ts

interface SpeechRecognitionCallback {
    onResult: (text: string) => void;
    onError: (error: Error) => void;
    onStart?: () => void;
    onEnd?: () => void;
  }
  
  class FallbackSpeechRecognition {
    private recognition: SpeechRecognition | null = null;
    private isListening: boolean = false;
    private callbacks: SpeechRecognitionCallback;
  
    constructor(callbacks: SpeechRecognitionCallback) {
      this.callbacks = callbacks;
      this.initializeSpeechRecognition();
    }
  
    private initializeSpeechRecognition() {
      try {
        // Use the standard SpeechRecognition or the webkit prefixed version
        const SpeechRecognitionAPI = window.SpeechRecognition || window.webkitSpeechRecognition;
        
        if (SpeechRecognitionAPI) {
          this.recognition = new SpeechRecognitionAPI();
          this.setupRecognition();
        } else {
          throw new Error('Speech recognition not supported in this browser');
        }
      } catch (error) {
        this.callbacks.onError(error instanceof Error ? error : new Error('Failed to initialize speech recognition'));
      }
    }
  
    private setupRecognition() {
      if (!this.recognition) return;
  
      this.recognition.continuous = true;
      this.recognition.interimResults = true;
      this.recognition.lang = 'en-US';
      this.recognition.maxAlternatives = 1;
  
      this.recognition.onresult = (event: SpeechRecognitionEvent) => {
        const result = event.results[event.resultIndex];
        if (result.isFinal) {
          this.callbacks.onResult(result[0].transcript);
        }
      };
  
      this.recognition.onerror = (event: SpeechRecognitionErrorEvent) => {
        this.callbacks.onError(new Error(`Speech recognition error: ${event.error} - ${event.message}`));
      };
  
      this.recognition.onstart = () => {
        this.isListening = true;
        this.callbacks.onStart?.();
      };
  
      this.recognition.onend = () => {
        this.isListening = false;
        this.callbacks.onEnd?.();
        
        // Automatically restart if we're supposed to be listening
        if (this.isListening) {
          try {
            this.recognition?.start();
          } catch (error) {
            // Ignore errors during auto-restart
          }
        }
      };
    }
  
    public start() {
      if (this.recognition && !this.isListening) {
        try {
          this.recognition.start();
          this.isListening = true;
        } catch (error) {
          this.callbacks.onError(error instanceof Error ? error : new Error('Failed to start speech recognition'));
        }
      }
    }
  
    public stop() {
      if (this.recognition && this.isListening) {
        try {
          this.recognition.stop();
          this.isListening = false;
        } catch (error) {
          this.callbacks.onError(error instanceof Error ? error : new Error('Failed to stop speech recognition'));
        }
      }
    }
  
    public isSupported(): boolean {
      return this.recognition !== null;
    }
  
    public isActive(): boolean {
      return this.isListening;
    }
  }
  
  export default FallbackSpeechRecognition;