import User from "../models/User";
import { PRODUCT_SUBSCRIPTION_VALUES } from "./constants";

interface AuthProvider {
  isAuthenticated: boolean;
  signin(): Promise<void>;
  signInWithCredentials(username: string, password: string): Promise<void>;
  signout(callback: VoidFunction): void;
}

const simulateTimeout = (): Promise<string> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve("Timeout completed");
    }, 300);
  });
};

const fakeAuthProvider: AuthProvider = {
  isAuthenticated: false,
  
  async signin() {
    fakeAuthProvider.isAuthenticated = true;
    await simulateTimeout();
  },
  
  async signInWithCredentials(username: string, password: string) {
    console.log(`Signing in with credentials: ${username}, ${password}`);
    await simulateTimeout();
    this.isAuthenticated = true;
  },
  
  signout(callback: VoidFunction) {
    fakeAuthProvider.isAuthenticated = false;
    setTimeout(callback, 100);
  },
};

export const handleLoadUserSession = (): User | undefined => {
  const userStr = localStorage.getItem("user");
  let user: User | undefined = undefined;
  if (userStr) {
    user = JSON.parse(userStr) as User;
  }
  return user;
};

export const handleSaveUserSession = (user: User): void => {
  const data = {
    country: user?.country,
    created: user?.created,
    type: user?.type,
    email: user?.email,
    emailValidation: user?.emailValidation,
    first_name: user?.first_name,
    hourlyRate: user?.hourlyRate,
    city: user?.city,
    company_name: user?.company_name,
    approvedExpert: user?.approvedExpert,
    name: user?.name,
    last_name: user?.last_name,
    onboarded: user?.onboarded,
    ubimaxId: user?.ubimaxId,
    validated: user?.validated,
    parent: {
      name: user?.parent?.name || "",
      type: user?.parent?.type,
      _id: user?.parent?._id,
    },
    subscription: {
      product: { name: user?.subscription?.product?.name },
    },
    _id: user?._id,
  };
  localStorage.setItem("user", JSON.stringify(data));
};

export const hanldeCleanSession = (): void => {
  localStorage.removeItem("jwt");
  localStorage.removeItem("user");
};

export const getEnterpriseId = (user: User | undefined): string | undefined => {
  if (!user) return undefined;
  if (
    [
      PRODUCT_SUBSCRIPTION_VALUES.ENTERPRISE,
      PRODUCT_SUBSCRIPTION_VALUES.UNLIMITED_ENTERPRISE,
    ].includes(user?.subscription?.product?.name)
  ) {
    return user?._id;
  }
  return user?.parent?._id || user?._id;
};

export { fakeAuthProvider };