import React, { useRef, useState, useEffect } from 'react';
import { 
  Box, 
  Button, 
  VStack, 
  Text,
  Spinner,
  useToast,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription
} from '@chakra-ui/react';

interface BasicCameraTestProps {
  onClose: () => void;
}

const BasicCameraTest: React.FC<BasicCameraTestProps> = ({ onClose }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [stream, setStream] = useState<MediaStream | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [status, setStatus] = useState<'idle' | 'loading' | 'active' | 'error'>('idle');
  const [deviceInfo, setDeviceInfo] = useState<string>('');
  const [permissionState, setPermissionState] = useState<string>('unknown');
  const toast = useToast();

  // Enhanced logging for browser capabilities
  const checkBrowserCapabilities = () => {
    const capabilities: Record<string, boolean> = {
      'navigator.mediaDevices': !!navigator.mediaDevices,
      'getUserMedia': !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia),
      'enumerateDevices': !!(navigator.mediaDevices && navigator.mediaDevices.enumerateDevices),
      'Permissions API': !!navigator.permissions,
      'Permissions Query': !!(navigator.permissions && navigator.permissions.query)
    };

    const report = Object.entries(capabilities)
      .map(([key, value]) => `${key}: ${value ? '✓' : '✗'}`)
      .join('\n');

    console.log('Browser Capabilities:\n', report);
    return report;
  };

  // More comprehensive permission check
  const checkPermission = async () => {
    try {
      if (navigator.permissions && navigator.permissions.query) {
        const result = await navigator.permissions.query({ name: 'camera' as PermissionName });
        setPermissionState(result.state);
        
        toast({
          title: 'Camera Permission',
          description: `Status: ${result.state}`,
          status: 'info',
          duration: 3000
        });
        
        console.log('Detailed Permission Check:', {
          state: result.state,
          capabilities: checkBrowserCapabilities()
        });
      } else {
        console.warn('Permissions API not available');
        setPermissionState('unsupported');
        toast({
          title: 'Permission API Unavailable',
          description: 'Browser does not support permission queries',
          status: 'warning',
          duration: 3000
        });
      }
    } catch (err) {
      const error = err as Error;
      console.error('Permission Check Error:', error);
      setPermissionState('error');
      toast({
        title: 'Permission Check Failed',
        description: error.message,
        status: 'error',
        duration: 3000
      });
    }
  };

  // Comprehensive camera start method
  const startCamera = async () => {
    try {
      setStatus('loading');
      setError(null);

      // Log browser capabilities before starting
      const browserCaps = checkBrowserCapabilities();
      console.log('Starting camera initialization');

      // Try multiple constraint approaches
      const constraintOptions = [
        { video: true, audio: false },
        { 
          video: { 
            width: { ideal: 1280 }, 
            height: { ideal: 720 }, 
            facingMode: 'environment' 
          }, 
          audio: false 
        },
        { 
          video: { 
            width: 640, 
            height: 480 
          }, 
          audio: false 
        }
      ];

      let mediaStream: MediaStream | null = null;
      for (const constraints of constraintOptions) {
        try {
          console.log('Trying constraints:', constraints);
          mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
          
          if (mediaStream) break;
        } catch (constraintError) {
          console.warn('Failed with constraints:', constraints, constraintError);
        }
      }

      if (!mediaStream) {
        throw new Error('Could not obtain camera stream with any constraints');
      }

      // Log track information
      const videoTracks = mediaStream.getVideoTracks();
      console.log(`Obtained ${videoTracks.length} video tracks`);
      
      videoTracks.forEach((track, index) => {
        console.log(`Track ${index} details:`, {
          label: track.label,
          kind: track.kind,
          enabled: track.enabled,
          settings: track.getSettings(),
          constraints: track.getConstraints()
        });
      });

      // Set the stream to the video element
      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream;
        
        try {
          await videoRef.current.play();
          console.log('Video is playing successfully');
        } catch (playError) {
          console.error('Video play error:', playError);
          throw new Error(`Failed to play video: ${(playError as Error).message}`);
        }
      }

      setStream(mediaStream);
      setStatus('active');
      
      toast({
        title: 'Camera started successfully',
        description: `Tracks: ${videoTracks.length}`,
        status: 'success',
        duration: 3000
      });
    } catch (err) {
      const error = err as Error;
      console.error('Comprehensive Camera Error:', {
        message: error.message,
        name: error.name,
        stack: error.stack
      });
      
      setError(error.message || 'Failed to start camera');
      setStatus('error');
      
      toast({
        title: 'Camera Initialization Failed',
        description: error.message,
        status: 'error',
        duration: 5000
      });
    }
  };

  // Comprehensive stop camera method
  const stopCamera = () => {
    console.log('Stopping camera - current stream:', !!stream);
    
    if (stream) {
      const tracks = stream.getTracks();
      console.log(`Stopping ${tracks.length} tracks`);
      
      tracks.forEach(track => {
        console.log(`Stopping track: ${track.label} (${track.kind})`);
        track.stop();
      });
      
      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
      
      setStream(null);
      setStatus('idle');
      
      toast({
        title: 'Camera Stopped',
        description: 'All camera tracks have been terminated',
        status: 'info',
        duration: 2000
      });
    } else {
      console.warn('No stream to stop');
      toast({
        title: 'No Active Camera',
        description: 'No camera stream is currently active',
        status: 'warning',
        duration: 2000
      });
    }
  };

  // On component mount, check initial capabilities
  useEffect(() => {
    checkBrowserCapabilities();
    checkPermission();
  }, []);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      stopCamera();
    };
  }, []);

  return (
    <Box p={4} borderWidth="1px" borderRadius="lg" width="100%" maxWidth="500px" mx="auto">
      <VStack spacing={4} align="stretch">
        <Text fontSize="xl" fontWeight="bold" textAlign="center">
          Comprehensive Camera Test
        </Text>
        
        <Alert status="info" variant="subtle">
          <AlertIcon />
          <AlertTitle mr={2}>Permission Status:</AlertTitle>
          <AlertDescription>{permissionState}</AlertDescription>
        </Alert>
        
        <Box
          width="100%"
          height="300px"
          bg="gray.800"
          borderRadius="md"
          overflow="hidden"
          position="relative"
        >
          {status === 'loading' && (
            <Box
              position="absolute"
              top="0"
              left="0"
              right="0"
              bottom="0"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="rgba(0,0,0,0.7)"
              zIndex="1"
            >
              <Spinner size="xl" color="white" />
            </Box>
          )}
          
          {status === 'error' && (
            <Box
              position="absolute"
              top="0"
              left="0"
              right="0"
              bottom="0"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="rgba(0,0,0,0.7)"
              zIndex="1"
              color="white"
              textAlign="center"
              p={4}
            >
              <Text>{error}</Text>
            </Box>
          )}
          
          <video
            ref={videoRef}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              display: status === 'active' ? 'block' : 'none'
            }}
            playsInline
          />
        </Box>
        
        <VStack spacing={3}>
          {status === 'idle' || status === 'error' ? (
            <Button 
              colorScheme="green" 
              onClick={startCamera}
              width="full"
            >
              Start Camera
            </Button>
          ) : (
            <Button 
              colorScheme="red" 
              onClick={stopCamera}
              width="full"
            >
              Stop Camera
            </Button>
          )}
          
          <Button 
            colorScheme="blue" 
            onClick={checkPermission}
            variant="outline"
            width="full"
          >
            Check Permissions
          </Button>
          
          <Button 
            onClick={onClose}
            variant="ghost"
            width="full"
          >
            Close
          </Button>
        </VStack>
      </VStack>
    </Box>
  );
};

export default BasicCameraTest;