export async function setupRealWearVoiceCommands() {
    if (window.WearHF) {
      console.log("RealWear HF API is available");
    } else {
      console.warn("WearHF is not available. Are you running on a RealWear device?");
    }
  }
  
  export function startRealWearListening() {
    if (window.WearHF) {
      window.WearHF.startRecognition();
    } else {
      console.warn("Cannot start RealWear listening: WearHF is not available");
    }
  }
  
  export function stopRealWearListening() {
    if (window.WearHF) {
      window.WearHF.stopRecognition();
    } else {
      console.warn("Cannot stop RealWear listening: WearHF is not available");
    }
  }
  
  export function addRealWearCommand(command: string, callback: () => void) {
    if (window.WearHF) {
      window.WearHF.addVoiceCommand(command, callback);
    } else {
      console.warn("Cannot add RealWear voice command: WearHF is not available");
    }
  }
  
  export function removeRealWearCommand(command: string) {
    if (window.WearHF) {
      window.WearHF.removeVoiceCommand(command);
    } else {
      console.warn("Cannot remove RealWear voice command: WearHF is not available");
    }
  }