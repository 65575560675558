import {
  extendTheme,
  withDefaultVariant,
  withDefaultProps,
} from "@chakra-ui/react";

export const orangeColor = "#F5AD56";

const buttonVariants = {
  primary: {
    bg: orangeColor,
    color: "#FFFFFF",
    textTransform: "uppercase",
    borderWidth: "1px",
    borderColor: orangeColor,
    _hover: {
      color: orangeColor,
      bg: "#fff",
      borderColor: orangeColor,
      borderWidth: "1px",
    },
  },
  primaryOutline: {
    color: orangeColor,
    textTransform: "uppercase",
    borderWidth: "1px",
    borderColor: orangeColor,
    _hover: {
      bg: orangeColor,
      color: "#FFFFFF",
      borderColor: orangeColor,
      borderWidth: "1px",
    },
  },
  accept: {
    color: "green",
    textTransform: "uppercase",
    borderWidth: "1px",
    borderColor: "green",
    _hover: {
      bg: "green",
      color: "#FFFFFF",
      borderColor: "green",
      borderWidth: "1px",
    },
  },
  cancel: {
    color: "red",
    textTransform: "uppercase",
    borderWidth: "1px",
    borderColor: "red",
    _hover: {
      bg: "red",
      color: "#FFFFFF",
      borderColor: "red",
      borderWidth: "1px",
    },
  },
};

const theme = extendTheme(
  {
    fonts: {
      heading: `'Open Sans', sans-serif`,
      body: `'Raleway', sans-serif`,
      label: `'Raleway', sans-serif`,
    },
    colors: {
      title: "#DE9769",
    },
    components: {
      FormLabel: {
        baseStyle: {
          fontFamily: "label",
        },
      },
      Button: {
        baseStyle: {
          fontFamily: "label",
        },
        variants: buttonVariants,
      },
      Spinner: {
        baseStyle: {
          color: "white",
        },
      },
      Checkbox: {
        baseStyle: {
          fontFamily: "label",
        },
      },
      Avatar: {
        variants: {
          square: {
            borderRadius: "14px",
            borderWidth: "10px",
          },
        },
      },
      Input: {
        baseStyle: {
          fontFamily: "label",
        },
      },
      NumberInput: {
        baseStyle: {
          fontFamily: "label",
        },
      },
      PinInput: {
        baseStyle: {
          fontFamily: "label",
        },
      },
    },
  },
  withDefaultVariant({
    variant: "outline",
    components: ["Input", "NumberInput", "PinInput"],
  }),
  withDefaultProps({
    defaultProps: {
      size: "lg",
    },
    components: ["Input", "NumberInput", "PinInput", "FormLabel", "Button"],
  })
);

export default theme;