import { Button, HStack, Text, VStack } from "@chakra-ui/react";
import "@tldraw/tldraw/tldraw.css";
import CallIndicator from "../components/CallIndicator";
import VideoPlayer from "../components/videoCalls/VideoPlayer";
import { useAppointmentVideoCall } from "../hooks/calls/appointment/useAppointmentVideoCall";
import { useNavigate } from "react-router";
import { useEffect, useMemo } from "react";
import { CONFIG } from "../utils/constants";
import { LaunchCallUsers } from "../models/CallUsers";

const AppointmentCall: React.FC = () => {
  const {
    callId,
    callEnded,
    callData,
    isCallStarting,
    isErrorStarting,
    errorStarting,
    isSuccessStarting,
    isTech,
    startCall,
    closeCall,
    setCallEnded,
  } = useAppointmentVideoCall();
  
  const navigate = useNavigate();

  // Determine call status based on call state
  const callStatus = useMemo(() => {
    if (callEnded) return 'disconnected';
    if (isErrorStarting) return 'disconnected';
    if (isCallStarting) return 'outgoing';
    if (isSuccessStarting) return 'connected';
    return 'incoming';
  }, [callEnded, isErrorStarting, isCallStarting, isSuccessStarting]);

  useEffect(() => {
    if (callEnded) {
      setTimeout(() => {
        navigate(-1);
      }, 3000);
    }
  }, [callEnded, navigate]);

  return (
    <VStack
      alignItems={"flex-start"}
      w={"full"}
      height={`calc(var(--vh, 1vh) * ${CONFIG.MAIN_HEIGHT})`}
    >
      <HStack spacing={4} pos={"absolute"} top={2} left={12} w={"25%"}>
        <Text fontWeight={"bold"} fontSize={"md"} color={"white"}>
          Appointment Call
        </Text>
        <CallIndicator status={callStatus} />
      </HStack>
      {callEnded && (
        <HStack
          w={"100%"}
          mt={"20%"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Text fontSize={"md"} color={"white"}>
            Call ended. Going back...
          </Text>
        </HStack>
      )}
      {!callEnded && callId && (
        <VideoPlayer
          channelId={callId}
          startCall={startCall}
          closeCall={closeCall}
          setCallEnded={setCallEnded}
          isCallStarting={isCallStarting}
          errorStarting={errorStarting}
          isSuccessStarting={isSuccessStarting}
          isErrorStarting={isErrorStarting}
          isTech={isTech}
          callData={callData}
          callEnded={callEnded}
          callId={callId}
        />
      )}
    </VStack>
  );
};

export default AppointmentCall;