import { ChakraProvider } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Capacitor } from '@capacitor/core';
import AuthProvider from "./contexts/AuthProvider";
import { useAuth } from "./hooks/useAuth";
import Layout from "./layouts/Layout";
import AppointmentCall from "./pages/AppointmentCall";
import DirectCall from "./pages/DirectCall";
import CallableUsers from "./pages/CallableUsers";
import LoginPage from "./pages/Login";
import ProcessWorkInstructions from "./pages/ProcessWorkInstructions";
import ProcessesHome from "./pages/ProcessesHome";
import SplashDashboard from "./pages/SplashDashboard";
import theme from "./theme/theme";
import ExecuteInstruction from "./pages/ExecuteInstruction";
import AppProvider from "./contexts/AppProvider";
import GlobalNotifications from "./components/notifications/GlobalNotifications";
import VideoInstructions from "./pages/VideoInstructions";
import { detectDevice, DeviceType } from './utils/DeviceDetection';
import { setupVoiceCommands } from './utils/voiceCommands';
import { SpeechRecognitionProvider } from './contexts/SpeechRecognitionContext';

declare global {
  interface Window {
    Capacitor: typeof Capacitor;
  }
}

interface SpeechRecognitionEvent extends CustomEvent {
  detail: {
    phrase: string;
  };
}

const protectedPages: { path: string; element: React.ReactElement }[] = [
  {
    path: "/dashboard",
    element: <SplashDashboard />,
  },
  {
    path: "/callable-users",
    element: <CallableUsers />,
  },
  {
    path: "/direct-call",
    element: <DirectCall />,
  },
  {
    path: "/processes/home",
    element: <ProcessesHome />,
  },
  {
    path: "/processes/list/:type",
    element: <ProcessWorkInstructions />,
  },
  {
    path: "/processes/execute/:id",
    element: <ExecuteInstruction />,
  },
  {
    path: "/appointment-call",
    element: <AppointmentCall />,
  },
  {
    path: "/video-instructions",
    element: <VideoInstructions />,
  },
];


export default function App() {
  const [deviceType, setDeviceType] = useState<DeviceType | null>(null);
  const [lastRecognizedPhrase, setLastRecognizedPhrase] = useState<string>("");
  
  useEffect(() => {
    const initializeApp = async () => {
      try {
        const detectedDeviceType = await detectDevice();
        setDeviceType(detectedDeviceType);
        
        // Initialize speech recognition if on native platform
        if (Capacitor.isNativePlatform()) {
          try {
            const plugin = Capacitor.Plugins.VuzixSpeechRecognition;
            await plugin.initialize();
            
            // Add some test voice commands
            await plugin.addCommand({ command: "hello vuzix" });
            await plugin.addCommand({ command: "open dashboard" });
            await plugin.addCommand({ command: "start work" });
            
            console.log('Speech recognition initialized');
          } catch (err) {
            console.error('Failed to initialize speech recognition:', err);
          }
        }

        await setupVoiceCommands(detectedDeviceType);
        console.log(`Device-specific setup completed for ${detectedDeviceType}`);
      } catch (error) {
        console.error("Error during device-specific setup:", error);
      }
    };

    initializeApp();

    // Set up speech recognition event listener
    const handleSpeechEvent = (event: SpeechRecognitionEvent) => {
      const { phrase } = event.detail;
      console.log('Recognized phrase:', phrase);
      setLastRecognizedPhrase(phrase);
      
      // Handle specific commands
      switch (phrase.toLowerCase()) {
        case 'open dashboard':
          // Navigate to dashboard
          console.log('Navigating to dashboard...');
          break;
        case 'start work':
          // Navigate to work instructions
          console.log('Navigating to work instructions...');
          break;
        // Add more command handlers as needed
      }
    };

    window.addEventListener('speechRecognition', handleSpeechEvent as EventListener);

    // Clean up event listener
    return () => {
      window.removeEventListener('speechRecognition', handleSpeechEvent as EventListener);
    };
  }, []);

  const adjustHeight = () => {
    const viewportHeight = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${viewportHeight}px`);
  };

  useEffect(() => {
    // Adjust on resize or orientation change
    window.addEventListener("resize", adjustHeight);
    window.addEventListener("orientationchange", adjustHeight);

    // Initial adjust
    adjustHeight();

    return () => {
      window.removeEventListener("resize", adjustHeight);
      window.removeEventListener("orientationchange", adjustHeight);
    };
  }, []);

  // You can use lastRecognizedPhrase in your UI if needed
  console.log('Last recognized phrase:', lastRecognizedPhrase);

  return (
    <ChakraProvider theme={theme}>
      <AppProvider>
        <AuthProvider>
        <SpeechRecognitionProvider deviceType={deviceType || DeviceType.ChromePC}>
          <Routes>
            <Route element={<Layout />}>
              {protectedPages.map((page) => (
                <Route
                  key={page.path}
                  path={page.path}
                  element={
                    <RequireAuth>
                      {React.cloneElement(page.element, { 
                        deviceType,
                        lastRecognizedPhrase // Pass to components if needed
                      })}
                    </RequireAuth>
                  }
                />
              ))}
              <Route path="/login" element={<LoginPage deviceType={deviceType ?? DeviceType.ChromePC} />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </Route>
          </Routes>
          <GlobalNotifications />
          </SpeechRecognitionProvider>
        </AuthProvider>
      </AppProvider>
    </ChakraProvider>
  );
}

function RequireAuth({ children }: { children: React.ReactElement }) {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}