import { DeviceType } from './DeviceDetection';
import FallbackSpeechRecognition from './FallbackSpeechRecognition';
import { setupVuzixVoiceCommands, startVuzixListening, stopVuzixListening } from './VuzixSpeechRecognition';
import { setupRealWearVoiceCommands, startRealWearListening, stopRealWearListening } from './RealWearSpeechRecognition';

interface SpeechRecognitionOptions {
  onResult: (text: string) => void;
  onError: (error: Error) => void;
  onStart?: () => void;
  onEnd?: () => void;
  deviceType: DeviceType;
}

class DeviceSpeechRecognition {
  private deviceType: DeviceType;
  private fallbackRecognition: FallbackSpeechRecognition | null = null;
  private callbacks: Omit<SpeechRecognitionOptions, 'deviceType'>;
  private primaryRecognitionFailed: boolean = false;
  private commandCallbacks: Map<string, () => void> = new Map();

  constructor(options: SpeechRecognitionOptions) {
    this.deviceType = options.deviceType;
    this.callbacks = {
      onResult: (text: string) => {
        this.handleRecognitionResult(text);
        options.onResult(text);
      },
      onError: this.handleError.bind(this),
      onStart: options.onStart,
      onEnd: options.onEnd
    };

    // Initialize fallback recognition immediately
    this.fallbackRecognition = new FallbackSpeechRecognition(this.callbacks);
  }

  private handleRecognitionResult(text: string) {
    // Check for registered commands in the recognized text
    const lowerText = text.toLowerCase();
    this.commandCallbacks.forEach((callback, command) => {
      if (lowerText.includes(command.toLowerCase())) {
        callback();
      }
    });
  }

  private handleError(error: Error) {
    console.error('Speech recognition error:', error);
    
    // If primary recognition fails, switch to fallback
    if (!this.primaryRecognitionFailed) {
      this.primaryRecognitionFailed = true;
      console.log('Switching to fallback speech recognition');
      this.startFallbackRecognition();
    }
    
    this.callbacks.onError(error);
  }

  private async startFallbackRecognition() {
    if (this.fallbackRecognition?.isSupported()) {
      try {
        this.fallbackRecognition.start();
      } catch (error) {
        console.error('Fallback recognition failed:', error);
        this.callbacks.onError(error instanceof Error ? error : new Error('Fallback recognition failed'));
      }
    }
  }

  public async initialize(): Promise<void> {
    try {
      switch (this.deviceType) {
        case DeviceType.Vuzix:
          await setupVuzixVoiceCommands();
          break;
        case DeviceType.RealWear:
          await setupRealWearVoiceCommands();
          break;
        default:
          // For other devices, we'll rely on the fallback
          this.primaryRecognitionFailed = true;
      }
    } catch (error) {
      console.error('Failed to initialize primary speech recognition:', error);
      this.primaryRecognitionFailed = true;
    }
  }

  public async start(): Promise<void> {
    if (this.primaryRecognitionFailed) {
      await this.startFallbackRecognition();
      return;
    }

    try {
      switch (this.deviceType) {
        case DeviceType.Vuzix:
          await startVuzixListening();
          break;
        case DeviceType.RealWear:
          await startRealWearListening();
          break;
        default:
          await this.startFallbackRecognition();
      }
    } catch (error) {
      console.error('Failed to start primary speech recognition:', error);
      this.primaryRecognitionFailed = true;
      await this.startFallbackRecognition();
    }
  }

  public async stop(): Promise<void> {
    try {
      if (this.primaryRecognitionFailed) {
        this.fallbackRecognition?.stop();
        return;
      }

      switch (this.deviceType) {
        case DeviceType.Vuzix:
          await stopVuzixListening();
          break;
        case DeviceType.RealWear:
          await stopRealWearListening();
          break;
        default:
          this.fallbackRecognition?.stop();
      }

      // Always stop fallback if it's running
      if (this.fallbackRecognition?.isActive()) {
        this.fallbackRecognition.stop();
      }
    } catch (error) {
      console.error('Error stopping speech recognition:', error);
      if (error instanceof Error) {
        this.callbacks.onError(error);
      }
    }
  }

  public addCommand(command: string, callback: () => void): void {
    // Store command callback for fallback recognition
    this.commandCallbacks.set(command, callback);

    // Add command to device-specific recognition if available
    try {
      switch (this.deviceType) {
        case DeviceType.Vuzix:
          // Add command to Vuzix
          break;
        case DeviceType.RealWear:
          if (window.WearHF?.addVoiceCommand) {
            window.WearHF.addVoiceCommand(command, callback);
          }
          break;
      }
    } catch (error) {
      console.error('Error adding command to primary recognition:', error);
    }
  }

  public removeCommand(command: string): void {
    this.commandCallbacks.delete(command);

    // Remove from device-specific recognition if available
    try {
      switch (this.deviceType) {
        case DeviceType.RealWear:
          if (window.WearHF?.removeVoiceCommand) {
            window.WearHF.removeVoiceCommand(command);
          }
          break;
      }
    } catch (error) {
      console.error('Error removing command:', error);
    }
  }
}

export default DeviceSpeechRecognition;