import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Spinner,
  Text,
  VStack,
  Progress,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { ChevronLeft, ChevronRight, CheckCircle, List } from 'lucide-react';
import { useNavigate, useParams } from "react-router";
import { useProcesses } from "../hooks/calls/useProcesses";
import { useApp } from "../hooks/useApp";
import CenteredContentPage from "../layouts/CenteredContentPage";
import { ProcessTeamUser } from "../models/ProcessTeamUser";
import COLORS from "../utils/colors";

const ExecuteInstruction: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const toast = useToast();
  const { setTitle } = useApp();
  const navigate = useNavigate();
  const [processUser, setProcessUser] = useState<ProcessTeamUser>();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  
  const {
    loadById,
    loadByIdAndExecute,
    handleOnLocalSave,
    processGoNext,
    pauseProcess,
    cancelProcess,
  } = useProcesses(true);

  const onLocalSave = useCallback(async () => {
    if (processUser?.process && id) {
      try {
        await handleOnLocalSave(processUser.process);
        await loadById(id);
        console.log("downloaded files");
      } catch (error) {
        console.log("error while downloading files");
        console.error(error);
      }
    }
  }, [handleOnLocalSave, id, loadById, processUser]);

  useEffect(() => {
    const loadProcess = async () => {
      if (!id) {
        toast({ title: "Invalid process ID", status: "error" });
        navigate("/processes/list/jobs", { replace: true });
        return;
      }

      try {
        const data = await loadByIdAndExecute(id);
        setProcessUser(data);
        setTitle(`1/${data.process.instructions.length} steps completed`);
      } catch (error) {
        toast({ title: "Cannot execute instruction", status: "error" });
        navigate("/processes/list/jobs", { replace: true });
      }
    };

    loadProcess();
  }, [id, loadByIdAndExecute, toast, navigate, setTitle]);

  const handleNext = async () => {
    if (!id) return;
    
    if (processUser && currentStep < processUser.process.instructions.length) {
      const nextStep = currentStep + 1;
      setCurrentStep(nextStep);
      setTitle(`${nextStep}/${processUser.process.instructions.length} steps completed`);
      await processGoNext(id, nextStep);
    } else if (processUser && currentStep === processUser.process.instructions.length) {
      await processGoNext(id, currentStep);
      navigate("/processes/list/jobs");
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      const previousStep = currentStep - 1;
      setCurrentStep(previousStep);
      setTitle(`${previousStep}/${processUser?.process.instructions.length} steps completed`);
    } else {
      setIsModalOpen(true);
    }
  };

  const handleLeaveTask = () => {
    setIsModalOpen(false);
    navigate("/processes/list/jobs");
  };

  const handleStayOnTask = () => {
    setIsModalOpen(false);
  };

  const handlePause = async () => {
    if (id) {
      await pauseProcess(id);
      navigate("/processes/list/jobs");
    }
  };

  const handleCancel = async () => {
    if (id) {
      await cancelProcess(id);
      navigate("/processes/list/jobs");
    }
  };

  useEffect(() => {
    onLocalSave();
  }, [processUser, id, onLocalSave]);

  const totalSteps = processUser?.process.instructions.length || 0;
  const currentInstruction = processUser?.process.instructions[currentStep - 1];

  return (
    <Box w={"full"} h="100%">
      <CenteredContentPage force boxProps={{ ml: 0, mr: 0, p: 0, mt: 0, pl: 0, h: "full" }}>
        <Box w="full" h="full" display="flex" alignItems="center" justifyContent="center">
          <VStack spacing={0} padding={2} h="full" w="full" maxW="7xl">
            {!processUser && <Spinner />}
            {processUser && currentInstruction && (
              <Box w="full" h="full" display="flex" flexDirection="column" bg="white" rounded="xl" shadow="md" overflow="hidden">
                <Box p={8} flexShrink={0} borderBottom="1px" borderColor="gray.200">
                  <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
                    <Text fontSize="4xl" fontWeight="bold" color="gray.800">{processUser.process.name}</Text>
                    <Button variant="outline" size="sm">
                      <List className="h-4 w-4" />
                    </Button>
                  </Box>
                  <Progress value={(currentStep / totalSteps) * 100} mb={4} />
                  <Text fontSize="lg" color="gray.500" mb={4}>{currentStep}/{totalSteps} steps completed</Text>
                </Box>
                <Box display="flex" flex={1} overflow="auto">
                  <Box flex={1} p={6} bg="orange.100" rounded="lg" m={4} display="flex" flexDirection="column" justifyContent="center">
                    <Text fontSize="2xl" fontWeight="semibold" color="orange.800" mb={2}>You need to...</Text>
                    <Text color="orange.700" fontSize="xl">{currentInstruction.description}</Text>
                  </Box>
                  {currentInstruction.files && currentInstruction.files.length > 0 && (
                    <Box flex={1} m={4} display="flex" justifyContent="center" alignItems="center">
                      <img 
                        src={currentInstruction.files[0].url} 
                        alt={`Step ${currentStep}`}
                        style={{ maxWidth: '100%', maxHeight: '100%', borderRadius: '12px' }}
                      />
                    </Box>
                  )}
                </Box>
                <Box display="flex" justifyContent="space-between" p={8} borderTop="1px" borderColor="gray.200">
                  <Button onClick={handleBack} disabled={currentStep === 1} variant="outline" size="lg">
                    <ChevronLeft className="mr-2 h-6 w-6" /> Back
                  </Button>
                  <Button onClick={handleNext} size="lg" colorScheme="orange">
                    {currentStep === totalSteps ? (
                      <>
                        <CheckCircle className="mr-2 h-6 w-6" /> Finish
                      </>
                    ) : (
                      <>
                        Next <ChevronRight className="ml-2 h-6 w-6" />
                      </>
                    )}
                  </Button>
                </Box>
              </Box>
            )}
          </VStack>
        </Box>
      </CenteredContentPage>
      <Modal isOpen={isModalOpen} onClose={handleStayOnTask}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Action</ModalHeader>
          <ModalBody>
            <Text>Are you sure you want to leave this task?</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={handleLeaveTask}>
              Yes
            </Button>
            <Button variant="ghost" onClick={handleStayOnTask}>No</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ExecuteInstruction;