import React, { useEffect, useState } from "react";
import {
  Box,
  VStack,
  HStack,
  Text,
  Flex,
  Badge,
  Button,
  Progress,
  Spinner,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useBreakpointValue,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { PlayCircle, RotateCcw, PlayCircle as ContinueIcon } from 'lucide-react';
import { useNavigate } from "react-router-dom";
import { useProcesses } from "../hooks/calls/useProcesses";
import { useApp } from "../hooks/useApp";
import { ProcessTeamUser } from "../models/ProcessTeamUser";
import { ProcessStatus } from "../enums/ProcessStatus";
import { GeneralTableFilters } from "../types/AppTypes";
import COLORS from "../utils/colors";

const Instruction: React.FC<{ item: ProcessTeamUser; index: number }> = ({ item, index }) => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const fontSize = useBreakpointValue({ base: "md", md: "lg", lg: "xl" });
  const startSize = useBreakpointValue({ base: "2xl", md: "3xl", lg: "4xl" });

  const handleAction = () => {
    if (item.status === "TODO") {
      navigate(`/processes/execute/${item.process._id}`);
    } else {
      onOpen();
    }
  };

  const handleNavigate = () => {
    navigate(`/processes/execute/${item.process._id}`);
    onClose();
  };

  const lastExecution = item.executionHistory[item.executionHistory.length - 1];
  const completedSteps = lastExecution ? lastExecution.completedSteps : 0;
  const totalSteps = item.process.instructions.length;
  const progress = (completedSteps / totalSteps) * 100;

  const getActionButton = () => {
    switch (item.status) {
      case ProcessStatus.DONE:
        return (
          <Button onClick={handleNavigate} colorScheme="blue" leftIcon={<RotateCcw />}>
            Restart Work Instruction {index + 1}
          </Button>
        );
      case ProcessStatus.PENDING:
        return (
          <Button onClick={handleNavigate} colorScheme="green" leftIcon={<ContinueIcon />}>
            Continue Work Instruction {index + 1}
          </Button>
        );
      default:
        return null;
    }
  };

  const getStatusColor = (status: ProcessStatus) => {
    switch (status) {
      case ProcessStatus.DONE:
        return "green.500";
      case ProcessStatus.PENDING:
        return "yellow.500";
      default:
        return COLORS.primary;
    }
  };

  const getBadgeColor = (status: ProcessStatus) => {
    switch (status) {
      case ProcessStatus.DONE:
        return "green";
      case ProcessStatus.PENDING:
        return "yellow";
      default:
        return "orange";
    }
  };

  return (
    <motion.div whileHover={{ scale: 1.01 }} whileTap={{ scale: 0.99 }}>
      <Box
        w="100%"
        borderRadius="lg"
        bg="white"
        boxShadow="md"
        overflow="hidden"
        mb={4}
        onClick={handleAction}
        cursor="pointer"
      >
        <HStack spacing={0}>
          <Flex
            alignItems="center"
            justifyContent="center"
            bg={getStatusColor(item.status)}
            color="white"
            width={{ base: "80px", md: "100px", lg: "120px" }}
            height="100%"
            p={2}
            flexDirection="column"
          >
            <Text fontWeight="bold" fontSize={fontSize}>START</Text>
            <Text fontWeight="bold" fontSize={startSize}>{index + 1}</Text>
          </Flex>
          <Box flex={1} p={4}>
            <VStack alignItems="flex-start" spacing={2}>
              <Text
                color="gray.800"
                fontWeight="bold"
                fontSize={fontSize}
                isTruncated
                maxWidth="100%"
              >
                {item.process.name}
              </Text>
              <HStack>
                <Badge 
                  colorScheme={getBadgeColor(item.status)}
                  fontSize={fontSize}
                >
                  {item.status}
                </Badge>
                <Text fontSize={fontSize} color="gray.600">
                  {completedSteps}/{totalSteps} steps
                </Text>
              </HStack>
              <Progress value={progress} size="sm" colorScheme="green" width="100%" />
            </VStack>
          </Box>
          <Flex alignItems="center" pr={4}>
            <PlayCircle size={24} color={COLORS.primary} />
          </Flex>
        </HStack>
      </Box>

      {(item.status === ProcessStatus.DONE || item.status === ProcessStatus.PENDING) && (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{item.process.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <VStack align="stretch" spacing={4}>
              <Text>Status: {item.status}</Text>
              <Text>Assigned by: {item.assignedBy.name}</Text>
              <Text>Comments: {item.comments}</Text>
              <Text>Relevance: {item.relevance}</Text>
              <Text>Progress: {completedSteps}/{totalSteps} steps completed</Text>
              <Progress value={progress} size="md" colorScheme="green" />
              {getActionButton()}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
      )}
    </motion.div>
  );
};

const ProcessWorkInstructions = () => {
  const { setTitle } = useApp();
  const [filters] = useState<GeneralTableFilters>({
    pageIndex: 0,
    pageSize: 10,
    sorting: [{ id: "status", desc: true }],
    text: "",
  });
  const { data, isLoading, load, isError } = useProcesses(true);
  const fontSize = useBreakpointValue({ base: "xl", md: "2xl", lg: "3xl" });

  useEffect(() => {
    setTitle("Work Instructions");
    load(filters);
  }, [setTitle, load, filters]);

  const renderContent = () => {
    if (isLoading) {
      return (
        <VStack>
          <Spinner size="xl" color="white" />
          <Text mt={4} color="white" fontSize={fontSize}>Loading work instructions...</Text>
        </VStack>
      );
    }

    if (isError) {
      return (
        <VStack>
          <Text color="red.300" fontSize={fontSize}>An error occurred while loading work instructions</Text>
          <Button onClick={() => load(filters)} mt={4} size="lg">
            Retry
          </Button>
        </VStack>
      );
    }

    if (data?.data && data.data.length > 0) {
      return (
        <VStack w="100%" spacing={6} align="stretch">
          <Text color="white" fontSize={fontSize} fontWeight="bold" textAlign="center" mb={8}>
            Say "START" followed by the number to begin a work instruction
          </Text>
          {data.data.map((item: ProcessTeamUser, index: number) => (
            <Instruction key={item._id} item={item} index={index} />
          ))}
        </VStack>
      );
    }

    return (
      <Text color="white" fontSize={fontSize} textAlign="center">No work instructions available.</Text>
    );
  };

  return (
    <Box bg={COLORS.primary} minHeight="100vh" minWidth="100vw" py={4} px={2}>
      <Box width="100%" maxWidth="1200px" mx="auto">
        {renderContent()}
      </Box>
    </Box>
  );
};

export default ProcessWorkInstructions;