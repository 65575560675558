import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useAuth } from "../useAuth";
import { ProcessTeamUser, Process } from "../../models/ProcessTeamUser";
import ProcessService from "../../services/ProcessService";
import { GeneralTableFilters, LazyData } from "../../types/AppTypes";
import { getCacheFiles } from "../../utils/cache";

interface UseProcessesResult {
  data: LazyData<ProcessTeamUser> | undefined;
  isLoading: boolean;
  isError: boolean;
  load: (filters: GeneralTableFilters) => Promise<void>;
  loadById: (id: string) => Promise<ProcessTeamUser>;
  loadByIdAndExecute: (id: string) => Promise<ProcessTeamUser>;
  handleOnLocalSave: (process: Process) => Promise<void>;
  processGoNext: (id: string, step: number) => Promise<void>;
  pauseProcess: (id: string) => Promise<void>;
  cancelProcess: (id: string) => Promise<void>;
}

export const useProcesses = (execute?: boolean): UseProcessesResult => {
  const { enterpriseId } = useAuth();
  const [data, setData] = useState<LazyData<ProcessTeamUser> | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = React.useState<boolean>(false);

  const load = useCallback(
    async (filters: GeneralTableFilters) => {
      console.log("filters", filters);

      if (enterpriseId) {
        try {
          setIsLoading(true);
          const res = await ProcessService.getProcesses(enterpriseId, filters);
          setData(res.data);
        } catch (error) {
          if (axios.isAxiosError(error)) {
            console.error(error.response?.data || error.message);
          }
          setIsError(true);
        } finally {
          setIsLoading(false);
        }
      }
    },
    [enterpriseId]
  );

  const pauseProcess = useCallback(async (id: string): Promise<void> => {
    if (!enterpriseId) {
      throw new Error('Enterprise ID is required');
    }
  
    try {
      await ProcessService.pauseProcess(enterpriseId, id);
    } catch (error) {
      console.error('Error pausing process:', error);
      setIsError(true);
      throw error;
    }
  }, [enterpriseId]);
  
  const cancelProcess = useCallback(async (id: string): Promise<void> => {
    if (!enterpriseId) {
      throw new Error('Enterprise ID is required');
    }
  
    try {
      await ProcessService.cancelProcess(enterpriseId, id);
    } catch (error) {
      console.error('Error canceling process:', error);
      setIsError(true);
      throw error;
    }
  }, [enterpriseId]);

  const loadById = useCallback(
    async (id: string): Promise<ProcessTeamUser> => {
      if (!enterpriseId) {
        throw new Error('Enterprise ID is required');
      }
  
      try {
        setIsLoading(true);
        const res = await ProcessService.getProcessesById(enterpriseId, id);
        if (!res.data) {
          throw new Error('Process not found');
        }
        return res.data;
      } catch (error) {
        setIsError(true);
        throw error;
      } finally {
        setIsLoading(false);
      }
    },
    [enterpriseId]
  );

  const loadByIdAndExecute = useCallback(
    async (id: string): Promise<ProcessTeamUser> => {
      if (!enterpriseId) {
        throw new Error('Enterprise ID is required');
      }

      try {
        setIsLoading(true);
        const res = await ProcessService.getProcessAndExecuteById(
          enterpriseId,
          id
        );
        if (!res.data) {
          throw new Error('Process not found');
        }
        return res.data;
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.error(error.response?.data || error.message);
        }
        setIsError(true);
        throw error;
      } finally {
        setIsLoading(false);
      }
    },
    [enterpriseId]
  );

  const processGoNext = useCallback(
    async (id: string, step: number): Promise<void> => {
      if (!enterpriseId) {
        throw new Error('Enterprise ID is required');
      }

      try {
        setIsLoading(true);
        await ProcessService.processGoNext(enterpriseId, id, step);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.error(error.response?.data || error.message);
        }
        setIsError(true);
        throw error;
      } finally {
        setIsLoading(false);
      }
    },
    [enterpriseId]
  );

  const handleOnLocalSave = useCallback(
    async (process: Process): Promise<void> => {
      if (!enterpriseId) {
        throw new Error('Enterprise ID is required');
      }
  
      try {
        const cache = await getCacheFiles();
        
        // Updated to use _id instead of id
        const processUrl = ProcessService.getProcessesByIdUrl(
          enterpriseId,
          process._id
        );
  
        // Get all file URLs from process instructions
        const fileUrls = process.instructions.flatMap((instruction) =>
          instruction.files.map((file) => file.url || "")
        ).filter(url => url); // Filter out empty URLs
  
        console.log("Cache invalidation starting...", {
          processUrl,
          fileCount: fileUrls.length
        });
  
        // Invalidate all caches
        await Promise.all([
          cache.delete(processUrl),
          ...fileUrls.map((url) =>
            cache.delete(url, { ignoreSearch: true })
          ),
        ]);
  
        console.log("Cache invalidated, reloading data...");
  
        // Reload process data and fetch all files
        await Promise.all([
          loadById(process._id), // Updated to use _id
          ...fileUrls.map(async (url) => {
            try {
              await fetch(url);
            } catch (error) {
              console.warn(`Failed to prefetch file: ${url}`, error);
            }
          }),
        ]);
  
        console.log("Local save completed successfully");
      } catch (error) {
        console.error("Error during local save:", error);
        throw new Error("Failed to complete local save");
      }
    },
    [enterpriseId, loadById]
  );

  useEffect(() => {
    if (enterpriseId && !execute) {
      load({ pageIndex: 0, pageSize: 10, sorting: [], text: "" });
    }
  }, [enterpriseId, load, execute]);

  return {
    data,
    isLoading,
    isError,
    load,
    loadById,
    loadByIdAndExecute,
    handleOnLocalSave,
    processGoNext,
    pauseProcess,
    cancelProcess,
  };
};