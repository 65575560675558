import {
  Box,
  Button,
  Heading,
  Input,
  Image,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  VStack,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useAuth } from "../hooks/useAuth";
import axios from "axios";
import logo from "/logo192.png";
import { QRScanner } from "../components/QRScanner/QRScanner";
import COLORS from "../utils/colors";

const LoginPage = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const [showQRScanner, setShowQRScanner] = useState(false);
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const from = location.state?.from?.pathname || "/dashboard";

  const onSubmit = async () => {
    try {
      await auth.signInWithCredentials(username, password);
      navigate(from, { replace: true });
    } catch (error) {
      console.error(error);
      toast({
        title: "Login failed",
        description: "Invalid username or password",
        status: "error",
      });
    }
  };

  const handleJwtQRCode = async (hash: string) => {
    try {
      await auth.signin(hash);
      navigate(from, { replace: true });
    } catch (error) {
      console.error(error);
      if (axios.isAxiosError(error)) {
        const msg = error.response?.data?.message || error.message;
        toast({ 
          title: "Cannot login", 
          description: msg,
          status: "error" 
        });
      } else {
        toast({ title: "Cannot login", status: "error" });
      }
    }
  };

  useEffect(() => {
    if (auth.user) {
      navigate("/dashboard");
    }
  }, [location, navigate]);

  return (
    <Box
      width="100%"
      height={"calc(var(--vh, 1vh) * 100)"}
      overflow="hidden"
      mx="auto"
      bg="linear-gradient(#006CB7, #16365E)"
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Box display="flex" justifyContent="center" alignItems="center">
        <Stack mx="auto" maxW="lg" px={6}>
          <Box
            rounded="lg"
            bg={useColorModeValue("white", "gray.700")}
            boxShadow="lg"
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            p={2}
          >
            <Stack>
              <Stack align="center" spacing={2}>
                <Image src={logo} alt="New Forge Logo" mx="auto" h="75px" />
                <Text fontSize="xl" fontWeight="bold" color={COLORS.primary}>
                  New Forge
                </Text>
                <Heading fontSize={"4xl"} textAlign="center" mt={2}>
                  Sign In
                </Heading>
              </Stack>
              <FormControl>
                <VStack spacing={4}>
                  <Input
                    fontSize={"sm"}
                    id="username"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <Input
                    fontSize={"sm"}
                    id="password"
                    placeholder="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />

                  <Button
                    variant="primaryOutline"
                    _hover={{
                      bg: "orange.500",
                    }}
                    isLoading={auth.isLoading}
                    fontSize={"md"}
                    onClick={onSubmit}
                    type="button"
                    w="full"
                  >
                    Sign In
                  </Button>

                  <Button
                    variant={"primary"}
                    _hover={{
                      bg: "white",
                      color: "orange.300",
                      border: "solid, .5px",
                    }}
                    fontSize={"md"}
                    isLoading={auth.isLoading}
                    onClick={() => setShowQRScanner(true)}
                    type="button"
                    w="full"
                  >
                    Sign in With QR code
                  </Button>
                </VStack>
              </FormControl>
            </Stack>
          </Box>
        </Stack>
      </Box>

      {showQRScanner && (
        <Modal
          isOpen={showQRScanner}
          motionPreset="slideInBottom"
          size="xs"
          onClose={() => setShowQRScanner(false)}
          closeOnOverlayClick={false}
        >
          <ModalOverlay />
          <ModalContent margin={"auto"}>
            <ModalHeader p={0} pl={2} fontSize={"sm"}>
              Scan QR Code
            </ModalHeader>
            <ModalCloseButton top={0} />
            <ModalBody borderRadius={20}>
              <QRScanner
                onResult={(result) => {
                  console.log("result", result);
                  if (result) {
                    handleJwtQRCode(result);
                    setShowQRScanner(false);
                  } else {
                    console.log("no result from qr code");
                  }
                }}
                onClose={() => setShowQRScanner(false)}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};

export default LoginPage;