import {
  Box,
  Button,
  HStack,
  Spinner,
  VStack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { v4 as uuid } from "uuid";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useAuth } from "../hooks/useAuth";
import { useApp } from "../hooks/useApp";
import { useVideoInstructions } from "../hooks/calls/useVideoInstructions";
import VideoRecorder from "../components/videoCalls/VideoRecorder";
import { ArrowBackIcon } from "@chakra-ui/icons";
import COLORS from "../utils/colors";

type VideoState = "start" | "recording" | "viewing";

const VideoInstructions: React.FC = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { setTitle } = useApp();
  const [channelId, setChannelId] = useState<string>("");
  const [videoState, setVideoState] = useState<VideoState>("start");
  const {
    handleStart,
    startRecording,
    stopRecording,
    enterpriseLibrary,
    isStopRecordingLoading,
    isStartRecordingLoading,
    isCreateEnterpriseLoading,
    isCreateEnterpriseError,
    isCreateEnterpriseSuccess
  } = useVideoInstructions();

  // Set page title
  useEffect(() => {
    setTitle("Video Instructions");
  }, [setTitle]);

  // Handle start recording
  const onStart = useCallback(async () => {
    try {
      // Clear any previous channel ID
      setChannelId("");
      
      // Generate a new channel ID if needed
      const id = channelId || uuid();
      if (!channelId) {
        setChannelId(id);
      }
      
      // Start recording process
      setVideoState("recording");
      await handleStart();
    } catch (error) {
      console.error("Error starting video recording:", error);
      toast({
        title: "Error",
        description: "Failed to start video recording",
        status: "error",
        duration: 3000,
      });
      setVideoState("start");
    }
  }, [channelId, handleStart, toast]);

  // Handle closing the recording
  const onCloseCallCallback = useCallback(() => {
    setVideoState("start");
    // Navigate back to dashboard
    navigate("/dashboard");
  }, [navigate]);

  if (videoState === "start") {
    return (
      <VStack
        w="full"
        h="100vh"
        justifyContent="center"
        alignItems="center"
        spacing={8}
        bg={COLORS.primary}
      >
        <Button
          size="lg"
          colorScheme="orange"
          onClick={onStart}
          isLoading={isCreateEnterpriseLoading}
        >
          Start Recording
        </Button>
        <Button leftIcon={<ArrowBackIcon />} onClick={() => navigate("/dashboard")}>
          Back to Dashboard
        </Button>
      </VStack>
    );
  }

  if (videoState === "recording") {
    // Check if we have a valid channel ID
    if (!channelId) {
      return (
        <VStack
          w="full"
          h="100vh"
          justifyContent="center"
          alignItems="center"
          spacing={4}
          bg={COLORS.primary}
        >
          <Spinner size="xl" color="white" />
          <Text color="white">Preparing recording session...</Text>
          <Button colorScheme="red" onClick={() => setVideoState("start")}>
            Cancel
          </Button>
        </VStack>
      );
    }

    return (
      <Box
        w="full"
        h="100vh"
        bg="black"
        position="relative"
      >
        <VideoRecorder
          channelId={channelId}
          startRecording={startRecording}
          stopRecording={stopRecording}
          onCloseCallCallback={onCloseCallCallback}
          isStartRecordingLoading={isStartRecordingLoading}
          isStopRecordingLoading={isStopRecordingLoading}
        />
      </Box>
    );
  }

  if (videoState === "viewing") {
    return (
      <VStack
        w="full"
        h="100vh"
        justifyContent="center"
        alignItems="center"
        spacing={4}
        bg={COLORS.primary}
      >
        <Text fontSize="xl" color="white">Video Recording Completed</Text>
        <Button onClick={() => setVideoState("start")}>
          Record Another Video
        </Button>
        <Button leftIcon={<ArrowBackIcon />} onClick={() => navigate("/dashboard")}>
          Back to Dashboard
        </Button>
      </VStack>
    );
  }

  // Default fallback
  return (
    <VStack
      w="full"
      h="100vh"
      justifyContent="center"
      alignItems="center"
      spacing={4}
      bg={COLORS.primary}
    >
      <Text color="white">Something went wrong. Please try again.</Text>
      <Button onClick={() => setVideoState("start")}>
        Back to Start
      </Button>
    </VStack>
  );
};

export default VideoInstructions;