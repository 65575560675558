import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import { BrowserRouter } from "react-router-dom";
// import { registerSW } from "virtual:pwa-register";
import * as Sentry from "@sentry/react";
import { detectDevice, DeviceType } from './utils/DeviceDetection.ts';
import { setupVoiceCommands } from './utils/voiceCommands';

// add this to prompt for a refresh
if (!import.meta.env.IS_CAPACITOR) {
  // Dynamically import the PWA register module
  import("virtual:pwa-register").then(({ registerSW }) => {
    // Register the service worker
    const updateSW = registerSW({
      onNeedRefresh() {
        if (confirm("New content available. Reload?")) {
          updateSW(true);
        }
      },
    });
  }).catch(error => {
    console.warn("PWA registration not available:", error);
  });
}

Sentry.init({
  dsn: "https://e366565df65b402c16e54e9a5ed25b57@o4509072488792064.ingest.us.sentry.io/4509072504651776",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.captureConsoleIntegration({ levels: ["warning", "error"] }),
  ],
  // Performance Monitoring
  tracesSampleRate: import.meta.env.MODE === "development" ? 0 : 0.1,
  environment: import.meta.env.MODE,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

// Device-specific setup
async function setupDeviceSpecifics() {
  try {
    const device = await detectDevice();
    await setupVoiceCommands(device);
    console.log(`Device-specific setup completed for ${device}`);
  } catch (error) {
    console.error("Error during device-specific setup:", error);
    Sentry.captureException(error);
  }
}

// Call the async function
setupDeviceSpecifics();

ReactDOM.createRoot(document.getElementById("root")!).render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);