import React, { useEffect, useState, useRef, useCallback } from "react";
import { 
  Box, 
  Text, 
  Button, 
  VStack, 
  Spinner, 
  useToast, 
  Alert,
  AlertIcon, 
  AlertTitle, 
  AlertDescription,
  useMediaQuery
} from "@chakra-ui/react";
import jsQR from "jsqr";
import { BiArrowBack } from "react-icons/bi";
import { DeviceType, detectDevice } from "../../utils/DeviceDetection";
import BasicCameraTest from "./BasicCameraTest";

interface QRScannerProps {
  onResult: (result: string | null) => void;
  onClose: () => void;
}

export const QRScanner: React.FC<QRScannerProps> = ({ onResult, onClose }) => {
  // Core state
  const [status, setStatus] = useState<'idle' | 'initializing' | 'scanning' | 'error' | 'timeout'>('idle');
  const [error, setError] = useState<string | null>(null);
  const [attempts, setAttempts] = useState<number>(0);
  const [deviceType, setDeviceType] = useState<DeviceType | null>(null);
  
  // Responsive layout
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  
  // Refs
  const mountedRef = useRef<boolean>(true);
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const scanIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const mediaStreamRef = useRef<MediaStream | null>(null);
  
  // Utilities
  const toast = useToast();

  // Detect device type on mount
  useEffect(() => {
    const detectDeviceType = async () => {
      try {
        const type = await detectDevice();
        setDeviceType(type);
      } catch (error) {
        console.error('Failed to detect device type:', error);
      }
    };
    
    detectDeviceType();
  }, []);

  // Clean up resources
  const cleanup = useCallback(() => {
    // Clear scanning interval
    if (scanIntervalRef.current) {
      clearInterval(scanIntervalRef.current);
      scanIntervalRef.current = null;
    }
    
    // Stop media stream
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach(track => {
        track.stop();
      });
      mediaStreamRef.current = null;
    }
    
    // Clear video source
    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }
  }, []);
  
  // Initialize camera directly without using a hook
  const initializeCamera = useCallback(async () => {
    if (!mountedRef.current) return;
    if (status === 'initializing') return;
    
    // Clean up any existing resources
    cleanup();
    
    // Update attempt count
    setAttempts(prev => prev + 1);
    
    // Set initializing state
    setStatus('initializing');
    setError(null);
    
    try {
      console.log('Directly initializing camera');
      
      // Configure constraints based on device type
      let constraints: MediaStreamConstraints;
      
      // Use environment-facing camera on mobile devices
      if (isMobile || 
          deviceType === DeviceType.AndroidMobile || 
          deviceType === DeviceType.Vuzix || 
          deviceType === DeviceType.RealWear) {
        constraints = {
          video: {
            facingMode: 'environment',
            width: { ideal: 1920 },
            height: { ideal: 1080 }
          } as MediaTrackConstraints,
          audio: false
        };
      } else {
        constraints = { video: true, audio: false };
      }
      
      console.log('Using constraints:', constraints);
      
      // Request camera with constraints
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      
      if (!mountedRef.current) {
        // Component unmounted during initialization
        stream.getTracks().forEach(track => track.stop());
        return;
      }
      
      // Store stream in ref
      mediaStreamRef.current = stream;
      
      // Set video source
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        
        // Force play the video
        try {
          await videoRef.current.play();
          console.log('Video playback started');
        } catch (playError) {
          console.warn('Video play error:', playError);
          // Try once more after a short delay
          setTimeout(async () => {
            if (videoRef.current && mountedRef.current) {
              try {
                await videoRef.current.play();
                console.log('Video playback started after retry');
              } catch (retryError) {
                console.error('Video play retry failed:', retryError);
              }
            }
          }, 500);
        }
      }
      
      // Start scanning after a short delay to ensure video is initialized
      setTimeout(() => {
        if (mountedRef.current) {
          startScanning();
        }
      }, 1000);
      
    } catch (error) {
      if (!mountedRef.current) return;
      
      console.error('Camera initialization error:', error);
      setError(error instanceof Error ? error.message : 'Failed to initialize camera');
      setStatus('error');
      
      // Show error toast
      toast({
        title: "Camera Error",
        description: error instanceof Error ? error.message : 'Camera initialization failed',
        status: "error",
        duration: 5000,
      });
      
      // Check if we should give up
      if (attempts >= 2) {
        setStatus('timeout');
      }
    }
  }, [status, cleanup, attempts, toast, isMobile, deviceType]);
  
  // Start QR code scanning
  const startScanning = useCallback(() => {
    if (!mountedRef.current) return;
    if (status !== 'initializing' || !videoRef.current || !canvasRef.current) return;
    
    console.log('Starting QR scanning');
    setStatus('scanning');
    
    // Clear any existing interval
    if (scanIntervalRef.current) {
      clearInterval(scanIntervalRef.current);
    }
    
    // Set up scanning interval
    scanIntervalRef.current = setInterval(() => {
      if (!mountedRef.current) {
        clearInterval(scanIntervalRef.current!);
        return;
      }
      
      const video = videoRef.current;
      const canvas = canvasRef.current;
      
      if (!video || !canvas) return;
      
      try {
        // Check if video is ready
        if (video.readyState === video.HAVE_ENOUGH_DATA) {
          // Set canvas size to match video
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          
          // Draw video frame to canvas
          const ctx = canvas.getContext('2d', { willReadFrequently: true });
          if (!ctx) return;
          
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          
          // Get image data for QR code detection
          const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
          
          // Scan for QR code
          const code = jsQR(imageData.data, imageData.width, imageData.height, {
            inversionAttempts: "dontInvert",
          });
          
          // If QR code found
          if (code) {
            console.log('QR code found:', code.data);
            
            // Stop scanning
            clearInterval(scanIntervalRef.current!);
            scanIntervalRef.current = null;
            
            // Clean up
            cleanup();
            
            // Return result
            onResult(code.data);
          }
        }
      } catch (error) {
        console.error('Error during QR scanning:', error);
      }
    }, 200);
    
  }, [status, cleanup, onResult]);
  
  // Handle retry
  const handleRetry = useCallback(() => {
    if (!mountedRef.current) return;
    
    // Reset state
    setStatus('idle');
    setError(null);
    setAttempts(0);
    
    // Clean up
    cleanup();
    
    // Try again after a short delay
    setTimeout(() => {
      if (mountedRef.current) {
        initializeCamera();
      }
    }, 500);
  }, [cleanup, initializeCamera]);
  
  // Handle close
  const handleClose = useCallback(() => {
    cleanup();
    onClose();
  }, [cleanup, onClose]);
  
  // Initialize on mount
  useEffect(() => {
    mountedRef.current = true;
    
    // Start camera after a short delay
    const timer = setTimeout(() => {
      if (mountedRef.current) {
        initializeCamera();
      }
    }, 300);
    
    // Cleanup on unmount
    return () => {
      mountedRef.current = false;
      clearTimeout(timer);
      cleanup();
    };
  }, [initializeCamera, cleanup]);

  // Video status display for debugging
  const StreamStatus = () => {
    const [videoStatus, setVideoStatus] = useState<string>('Unknown');
    const [videoSize, setVideoSize] = useState<string>('0x0');
    
    useEffect(() => {
      if (!videoRef.current) return;
      
      const checkInterval = setInterval(() => {
        if (!videoRef.current || !mountedRef.current) return;
        
        const video = videoRef.current;
        
        // Update size
        setVideoSize(`${video.videoWidth || 0}x${video.videoHeight || 0}`);
        
        // Update status
        const states = [
          'HAVE_NOTHING', 
          'HAVE_METADATA', 
          'HAVE_CURRENT_DATA', 
          'HAVE_FUTURE_DATA', 
          'HAVE_ENOUGH_DATA'
        ];
        setVideoStatus(states[video.readyState] || 'Unknown');
      }, 500);
      
      return () => clearInterval(checkInterval);
    }, []);
    
    return (
      <Box
        position="absolute"
        top="10px"
        right="10px"
        bg="rgba(0,0,0,0.7)"
        color="white"
        p={2}
        borderRadius="md"
        fontSize="xs"
        zIndex={10}
      >
        <Text fontWeight="bold">Debug Info:</Text>
        <Text>Status: {status}</Text>
        <Text>Video: {videoStatus}</Text>
        <Text>Size: {videoSize}</Text>
        <Text>Device: {deviceType || 'Unknown'}</Text>
        <Text>Mobile: {isMobile ? 'Yes' : 'No'}</Text>
      </Box>
    );
  };
  
  // Render loading state
  const renderLoading = () => (
    <VStack spacing={4} p={4} align="center" justify="center" h="100%">
      <Spinner size="xl" color="blue.500" />
      <Text fontSize="lg">Initializing camera...</Text>
      <Text fontSize="sm" color="gray.500">Attempt {attempts}/3</Text>
    </VStack>
  );
  
  // Render error state
  const renderError = () => (
    <VStack spacing={4} p={4} align="center" justify="center" h="100%">
      <Alert status="error" borderRadius="md">
        <AlertIcon />
        <VStack align="flex-start" spacing={2}>
          <AlertTitle>Camera Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </VStack>
      </Alert>
      <Button colorScheme="blue" onClick={handleRetry} my={2}>
        Try Again
      </Button>
      <Button onClick={handleClose} leftIcon={<BiArrowBack />}>
        Go Back
      </Button>
    </VStack>
  );
  
  // Render scanner - with responsive sizing
  const renderScanner = () => {
    // Determine scanner size based on device
    // For mobile or smart glasses, use maximum available height
    // For desktop, use a fixed size
    const scannerHeight = isMobile || 
                          deviceType === DeviceType.Vuzix || 
                          deviceType === DeviceType.RealWear 
                          ? "70vh" : "400px";
                          
    return (
      <Box 
        position="relative" 
        width="100%" 
        height={scannerHeight} 
        bg="black"
        maxWidth={isMobile ? "100%" : "600px"}
        mx="auto"
        borderRadius={isMobile ? "0" : "md"}
        overflow="hidden"
      >
        {/* Scanner frame */}
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          zIndex="2"
          borderWidth="2px"
          borderStyle="solid"
          borderColor="blue.500"
          pointerEvents="none"
        >
          {/* Target guidelines */}
          <Box
            position="absolute"
            top="10%"
            left="10%"
            right="10%"
            bottom="10%"
            border="2px dashed white"
            pointerEvents="none"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {/* Center target */}
            <Box
              width="80px"
              height="80px"
              border="2px solid rgba(255,255,255,0.8)"
              borderRadius="2px"
              position="relative"
            >
              {/* Corner markers */}
              <Box position="absolute" top="-5px" left="-5px" width="15px" height="15px" borderTop="3px solid #fff" borderLeft="3px solid #fff" />
              <Box position="absolute" top="-5px" right="-5px" width="15px" height="15px" borderTop="3px solid #fff" borderRight="3px solid #fff" />
              <Box position="absolute" bottom="-5px" left="-5px" width="15px" height="15px" borderBottom="3px solid #fff" borderLeft="3px solid #fff" />
              <Box position="absolute" bottom="-5px" right="-5px" width="15px" height="15px" borderBottom="3px solid #fff" borderRight="3px solid #fff" />
            </Box>
          </Box>
        </Box>
        
        {/* Scanning indicator */}
        <Box
          position="absolute"
          top="0"
          left="0"
          right="0"
          height="4px"
          bg="blue.400"
          zIndex="3"
          opacity="0.8"
          animation="scanline 2s ease-in-out infinite"
          sx={{
            "@keyframes scanline": {
              "0%": { top: "0%" },
              "50%": { top: "96%" },
              "100%": { top: "0%" },
            }
          }}
        />
        
        {/* Instructions */}
        <Text
          position="absolute"
          top="10px"
          left="0"
          right="0"
          textAlign="center"
          color="white"
          fontWeight="bold"
          fontSize={isMobile ? "sm" : "md"}
          textShadow="0 0 4px black"
          zIndex="3"
        >
          Align QR code within the frame
        </Text>
        
        {/* Video display */}
        <Box
          width="100%"
          height="100%"
          overflow="hidden"
          position="relative"
        >
          <video
            ref={videoRef}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
            playsInline
            muted
            autoPlay
          />
        </Box>
        
        {/* Hidden canvas for processing */}
        <canvas 
          ref={canvasRef}
          style={{ display: 'none' }}
        />
        
        {/* Cancel button */}
        <Button
          position="absolute"
          bottom="20px"
          left="50%"
          transform="translateX(-50%)"
          onClick={handleClose}
          leftIcon={<BiArrowBack />}
          zIndex="3"
          colorScheme="blue"
          opacity="0.9"
          size={isMobile ? "lg" : "md"}
        >
          Cancel
        </Button>
      </Box>
    );
  };
  
  // Render content based on state
  const renderContent = () => {
    // Show basic camera test if timed out
    if (status === 'timeout') {
      return <BasicCameraTest onClose={handleClose} />;
    }
    
    // Show error if there is one
    if (status === 'error') {
      return renderError();
    }
    
    // Show scanner if scanning
    if (status === 'scanning' || status === 'initializing') {
      return renderScanner();
    }
    
    // Show loading by default
    return renderLoading();
  };
  
  // Main render
  return (
    <Box 
      position="relative" 
      width="100%" 
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      bg={isMobile ? "black" : "transparent"}
    >
      {process.env.NODE_ENV === 'development' && <StreamStatus />}
      {renderContent()}
    </Box>
  );
};

export default QRScanner;