interface SpeechRecognitionResultOutput {
  transcript: string;
  isFinal: boolean;
}

class CustomSpeechRecognition {
  private recognition: SpeechRecognition | null = null;
  private isListening: boolean = false;
  private onResultCallback: ((result: SpeechRecognitionResultOutput) => void) | null = null;
  private onErrorCallback: ((error: Error) => void) | null = null;

  constructor() {
    // Type assertion for the window object
    const w = window as unknown as {
      webkitSpeechRecognition?: new () => SpeechRecognition;
      SpeechRecognition?: new () => SpeechRecognition;
    };

    if (w.webkitSpeechRecognition) {
      this.recognition = new w.webkitSpeechRecognition();
      this.setupRecognition();
    } else if (w.SpeechRecognition) {
      this.recognition = new w.SpeechRecognition();
      this.setupRecognition();
    } else {
      console.warn('Speech recognition is not supported in this browser.');
    }
  }

  private setupRecognition() {
    if (this.recognition) {
      this.recognition.continuous = true;
      this.recognition.interimResults = true;
      this.recognition.lang = 'en-US';

      this.recognition.onresult = (event: SpeechRecognitionEvent) => {
        const result = event.results[event.results.length - 1];
        const transcriptResult: SpeechRecognitionResultOutput = {
          transcript: result[0].transcript,
          isFinal: result.isFinal
        };
        if (this.onResultCallback) {
          this.onResultCallback(transcriptResult);
        }
      };

      this.recognition.onerror = (event: SpeechRecognitionErrorEvent) => {
        if (this.onErrorCallback) {
          this.onErrorCallback(new Error(`Speech recognition error: ${event.error}`));
        }
      };
    }
  }

  public start(
    onResult: (result: SpeechRecognitionResultOutput) => void,
    onError: (error: Error) => void
  ) {
    if (this.recognition && !this.isListening) {
      this.onResultCallback = onResult;
      this.onErrorCallback = onError;
      this.recognition.start();
      this.isListening = true;
    }
  }

  public stop() {
    if (this.recognition && this.isListening) {
      this.recognition.stop();
      this.isListening = false;
    }
  }

  public isSupported(): boolean {
    return this.recognition !== null;
  }
}

export default CustomSpeechRecognition;