import { registerPlugin } from '@capacitor/core';

interface VuzixSpeechRecognitionPlugin {
  initialize(): Promise<void>;
  start(): Promise<void>;
  stop(): Promise<void>;
  addVoiceCommand(options: { command: string }): Promise<void>;
}

const VuzixSpeechRecognition = registerPlugin<VuzixSpeechRecognitionPlugin>('VuzixSpeechRecognition');

export async function setupVuzixVoiceCommands() {
  try {
    await VuzixSpeechRecognition.initialize();
    console.log("Vuzix speech recognition initialized.");
  } catch (error) {
    console.error('Error initializing Vuzix speech recognition:', error);
  }
}

export function startVuzixListening() {
  return VuzixSpeechRecognition.start();
}

export function stopVuzixListening() {
  return VuzixSpeechRecognition.stop();
}

export function addVuzixCommand(command: string, callback: () => void) {
  return VuzixSpeechRecognition.addVoiceCommand({ command })
    .then(callback)
    .catch((error) => console.error('Error adding Vuzix voice command:', error));
}