import axios, { 
  CreateAxiosDefaults, 
  InternalAxiosRequestConfig,
  AxiosError,
  AxiosResponse
} from "axios";
import { GeneralTableFilters } from "../types/AppTypes";
import _ from "lodash";

const BASE_URL = import.meta.env.IS_CAPACITOR 
  ? '/api/v1'  // This will use the same origin as the app
  : `${import.meta.env.VITE_APP_API_HOST}/api/v1`;

interface createHttpClientProps extends CreateAxiosDefaults {}

interface FailedRequest {
  method: string;
  url: string;
}

const createHttpClient = (config?: createHttpClientProps) => {
  const instance = axios.create({
    baseURL: BASE_URL,
    ...config,
  });

  // Set the Bearer token in the Authorization header for all requests
  instance.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      const token = localStorage.getItem("jwt");
      if (token) {
        config.headers = config.headers || {};
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error: AxiosError) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError) => {
      // if error while offline
      if (
        error.config?.url?.match(
          /\/client\/process\/user-team\/[^/]+\/next-step\/[^/]+/
        )
      ) {
        try {
          const failedRequests: FailedRequest[] = JSON.parse(
            localStorage.getItem("failedRequests") || "[]"
          );
          
          if (error.config?.method && error.config.baseURL) {
            failedRequests.push({
              method: error.config.method,
              url: `${error.config.baseURL}${error.config.url}`,
            });

            localStorage.setItem("failedRequests", JSON.stringify(failedRequests));
          }
        } catch (storageError) {
          console.error('Error handling failed request storage:', storageError);
        }
      }
      // Handle error globally
      return Promise.reject(error);
    }
  );

  return instance;
};

export const processFilters = (filters: GeneralTableFilters): string => {
  const firstFilters = new URLSearchParams(
    _.omit(filters, "sorting") as Record<string, string>
  ).toString();

  return filters.sorting?.length
    ? `${firstFilters}&sorting[]=${encodeURIComponent(JSON.stringify(filters.sorting[0]))}`
    : firstFilters;
};

export default createHttpClient;