import React from 'react';
import { Box } from "@chakra-ui/react";
import { motion } from "framer-motion";

export interface CallIndicatorProps {
  status?: 'incoming' | 'outgoing' | 'connected' | 'disconnected' | 'recording';
  size?: string;
  colorFrom?: string;
  colorTo?: string;
  customColor?: string;
}

const MotionBox = motion(Box);

const CallIndicator: React.FC<CallIndicatorProps> = ({ 
  status, 
  size = "12px",
  colorFrom,
  colorTo,
  customColor 
}) => {
  const getColor = () => {
    if (customColor) return customColor;
    if (colorFrom || colorTo) return colorTo || colorFrom;

    switch (status) {
      case 'incoming':
        return 'yellow.500';
      case 'outgoing':
        return 'blue.500';
      case 'connected':
        return 'green.500';
      case 'disconnected':
        return 'red.500';
      case 'recording':
        return 'red.500';
      default:
        return 'gray.500';
    }
  };

  const animationProps = {
    animate: {
      scale: [1, 1.2, 1],
      opacity: [0.6, 1, 0.6],
      backgroundColor: colorFrom && colorTo ? [colorFrom, colorTo, colorFrom] : undefined,
    },
    transition: {
      duration: 1.5,
      ease: "linear",
      repeat: Infinity,
    },
  };

  return (
    <MotionBox
      w={size}
      h={size}
      borderRadius="full"
      bg={getColor()}
      {...animationProps}
      style={{ display: 'inline-block' }}
    />
  );
};

export default CallIndicator;