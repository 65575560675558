import {
  Box,
  Button,
  HStack,
  Spinner,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import AgoraRTC, { 
  IAgoraRTCClient, 
  IAgoraRTCRemoteUser,
  ILocalAudioTrack, 
  ILocalVideoTrack, 
  ICameraVideoTrack,
  IRemoteAudioTrack,
  IRemoteVideoTrack,
  UID,
  IMicrophoneAudioTrack,
  ClientRole,
  ScreenVideoTrackInitConfig,
  VideoEncoderConfiguration, 
  CameraVideoTrackInitConfig
} from 'agora-rtc-sdk-ng';
import { useCallback, useEffect, useRef, useState } from "react";
import { BiMicrophone, BiMicrophoneOff } from "react-icons/bi";
import { FaStop } from "react-icons/fa";
import { FiCamera, FiCameraOff } from "react-icons/fi";
import { TiMediaRecord } from "react-icons/ti";
import CallIndicator from "../CallIndicator";
import { useAuth } from "../../hooks/useAuth";
import COLORS from "../../utils/colors";
import { CONFIG } from "../../utils/constants";
import { RecordingBody } from "../../models/EnterprisLibrary";
import { useAgoraToken } from '../../hooks/calls/useAgoraToken';

interface Resolution {
  width: number;
  height: number;
}

// Set log level for Agora SDK
AgoraRTC.setLogLevel(1); // Set to 1 for more detailed logs

const resolutions: Resolution[] = [
  { width: 640, height: 480 },   // VGA
  { width: 1024, height: 768 },  // XGA
  { width: 1280, height: 720 },  // HD
  { width: 1920, height: 1080 }, // Full HD
];

type ConnectionState = 'disconnected' | 'connecting' | 'connected' | 'reconnecting' | 'error';

interface ConnectionStatus {
  state: ConnectionState;
  lastError?: Error;
  lastConnected?: Date;
}

interface IYouVideoProps {
  isVideoOn: boolean;
  width: number | string;
}

interface IBottomActionsProps {
  handleRecord: () => void;
  onCloseCall: () => void;
  isRecording: boolean;
  isStartRecordingLoading: boolean;
  isStopRecordingLoading: boolean;
  connectionStatus: ConnectionStatus;
}

interface IVideoRecorderProps {
  channelId: string;
  startRecording: (params: RecordingBody) => Promise<void>;
  stopRecording: (params: RecordingBody) => Promise<void>;
  onCloseCallCallback: (params?: any) => void;
  isStartRecordingLoading: boolean;
  isStopRecordingLoading: boolean;
  onConnectionStateChange?: (state: ConnectionState) => void;
}

// Create a single Agora client instance to reuse
const client: IAgoraRTCClient = AgoraRTC.createClient({
  mode: "rtc",
  codec: "vp8"
});

// YouVideo Component
const YouVideo: React.FC<IYouVideoProps> = ({ isVideoOn, width }) => {
  return (
    <VStack
      w={width || "15%"}
      pos="relative"
      top={0}
      left={0}
      zIndex={1}
      width="auto"
      height={`calc(var(--vh, 1vh) * ${CONFIG.MAIN_HEIGHT})`}
    >
      <Text
        zIndex={1}
        position="absolute"
        bottom={0}
        fontSize="sm"
        fontWeight="bold"
        paddingLeft={2}
        paddingRight={2}
        backgroundColor="rgb(255 255 255 / 50%)"
      />
      <video
        style={{
          borderWidth: 2,
          borderColor: "black",
          borderStyle: "solid",
          borderRadius: 2,
          background: "black",
          transform: "none",
        }}
        id="camera-video"
        hidden={!isVideoOn}
      />
    </VStack>
  );
};

// Connection Status Indicator Component
const ConnectionStatusIndicator: React.FC<{ status: ConnectionStatus }> = ({ status }) => {
  const getIndicatorStatus = (): 'connected' | 'disconnected' | 'recording' => {
    switch (status.state) {
      case 'connected':
        return 'connected';
      case 'connecting':
      case 'reconnecting':
        return 'recording'; // Use recording animation for connecting states
      default:
        return 'disconnected';
    }
  };

  return (
    <HStack spacing={2} alignItems="center">
      <CallIndicator status={getIndicatorStatus()} />
      <Text fontSize="sm" color={status.state === 'error' ? 'red.500' : 'inherit'}>
        {status.state.charAt(0).toUpperCase() + status.state.slice(1)}
      </Text>
    </HStack>
  );
};

// Bottom Actions Component
const BottomActions: React.FC<IBottomActionsProps> = ({
  onCloseCall,
  handleRecord,
  isRecording,
  isStartRecordingLoading,
  isStopRecordingLoading,
  connectionStatus,
}) => (
  <HStack
    w="full"
    alignSelf="flex-end"
    justifyContent="space-between"
    pos="absolute"
    bottom={0}
    left={0}
    right={0}
    paddingX={5}
    zIndex={1800}
  >
    <HStack gap={0} justifyContent="center" backgroundColor="white">
      <ConnectionStatusIndicator status={connectionStatus} />
      <Button
        size="sm"
        onClick={handleRecord}
        variant="ghost"
        isLoading={isStopRecordingLoading || isStartRecordingLoading}
        isDisabled={connectionStatus.state !== 'connected'}
        title={isRecording ? "Stop Recording" : "Start Recording"}
      >
        {isRecording ? (
          <HStack>
            <FaStop size={25} />
            <CallIndicator status="recording" />
          </HStack>
        ) : (
          <TiMediaRecord size={25} />
        )}
      </Button>
    </HStack>
    <HStack>
      <Button size="sm" colorScheme="red" onClick={onCloseCall}>
        Close
      </Button>
    </HStack>
  </HStack>
);

// Main VideoRecorder Component
const VideoRecorder: React.FC<IVideoRecorderProps> = ({
  channelId,
  startRecording,
  stopRecording,
  isStartRecordingLoading,
  isStopRecordingLoading,
  onCloseCallCallback,
  onConnectionStateChange
}) => {
  // Hooks and state
  const { enterpriseId } = useAuth();
  const toast = useToast();
  const appid = useRef<string>(import.meta.env.VITE_APP_AGORA_VIDEO || '');
  const { token, uid, fetchToken, tokenError, isTokenLoading } = useAgoraToken(channelId);
  
  // Reference for video element
  const videoRef = useRef<HTMLVideoElement>(null);
  
  // Tracks and media
  const videoTrack = useRef<ILocalVideoTrack | null>(null);
  const audioTrack = useRef<ILocalAudioTrack | null>(null);
  
  // UI state
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isVideoOn, setIsVideoOn] = useState<boolean>(false);
  const [isAudioOn, setIsAudioOn] = useState<boolean>(false);
  const [isVideoPubed, setIsVideoPubed] = useState<boolean>(false);
  const [isAudioPubed, setIsAudioPubed] = useState<boolean>(false);
  const [isRemoteVideoPubed, setIsRemoteVideoPubed] = useState<boolean>(false);
  const [isVideoMuted, setIsVideoMuted] = useState<boolean>(false);
  const [isAudioMuted, setIsAudioMuted] = useState<boolean>(false);
  const [agoraUserId, setAgoraUserId] = useState<UID | null>(null);
  const [isJoined, setIsJoined] = useState<boolean>(false);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [initializeAttempts, setInitializeAttempts] = useState<number>(0);
  const [loadingStatus, setLoadingStatus] = useState<string>("Initializing...");
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>({
    state: 'disconnected'
  });
  
  // Debug logging of important state variables
  useEffect(() => {
    console.log('💡 Debug - Connection status:', connectionStatus.state);
    console.log('💡 Debug - Connection state:', client.connectionState);
    console.log('💡 Debug - UID:', uid);
    console.log('💡 Debug - Token:', token.current ? token.current.substring(0, 20) + '...' : 'Not Available');
    console.log('💡 Debug - isLoading:', isLoading);
    console.log('💡 Debug - isTokenLoading:', isTokenLoading);
    console.log('💡 Debug - isJoined:', isJoined);
    console.log('💡 Debug - initializeAttempts:', initializeAttempts);
  }, [connectionStatus.state, uid, token, isLoading, isTokenLoading, isJoined, initializeAttempts]);

  // Connection state monitoring
  useEffect(() => {
    const handleConnectionStateChange = (curState: string) => {
      console.log("🔄 Agora connection state changed:", curState);
      
      let newState: ConnectionState;
      switch (curState) {
        case 'CONNECTING':
          newState = 'connecting';
          setLoadingStatus("Connecting to video service...");
          break;
        case 'CONNECTED':
          newState = 'connected';
          setLoadingStatus("Connected!");
          setIsLoading(false); // Ensure loading is finished when connected
          break;
        case 'DISCONNECTED':
          newState = 'disconnected';
          setLoadingStatus("Disconnected from video service");
          break;
        case 'RECONNECTING':
          newState = 'reconnecting';
          setLoadingStatus("Reconnecting to video service...");
          break;
        default:
          newState = 'error';
          setLoadingStatus("Connection error");
      }

      setConnectionStatus(prev => ({
        ...prev,
        state: newState,
        lastConnected: newState === 'connected' ? new Date() : prev.lastConnected
      }));

      // Call external state change handler if provided
      if (onConnectionStateChange) {
        onConnectionStateChange(newState);
      }
    };

    console.log("⚡ Setting up connection state handler");
    client.on('connection-state-change', handleConnectionStateChange);

    return () => {
      console.log("⚡ Removing connection state handler");
      client.off('connection-state-change', handleConnectionStateChange);
    };
  }, [onConnectionStateChange]);

  // Cleanup tracks helper
  const cleanupTracks = useCallback(async () => {
    try {
      console.log("🧹 Cleaning up tracks");
      if (videoTrack.current) {
        videoTrack.current.stop();
        videoTrack.current.close();
        videoTrack.current = null;
      }
      if (audioTrack.current) {
        audioTrack.current.stop();
        audioTrack.current.close();
        audioTrack.current = null;
      }
      setIsVideoOn(false);
      setIsAudioOn(false);
    } catch (error) {
      console.error('Error cleaning up tracks:', error);
    }
  }, []);

  // Leave channel and clean up
  const leaveCall = useCallback(async () => {
    try {
      console.log("📞 Leaving call");
      await cleanupTracks();
      
      if (client.connectionState !== 'DISCONNECTED') {
        console.log("🔌 Leaving Agora channel...");
        await client.leave();
      }
      
      setIsJoined(false);
      setIsVideoPubed(false);
      setIsAudioPubed(false);
      setIsRemoteVideoPubed(false);
      setConnectionStatus({
        state: 'disconnected'
      });
    } catch (error) {
      console.error('Error leaving call:', error);
    }
  }, [cleanupTracks]);

  // Handle cleanup on component unmount
  useEffect(() => {
    const handleBeforeUnload = () => {
      leaveCall();
    };
  
    window.addEventListener("beforeunload", handleBeforeUnload);
  
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      console.log("🧨 Component unmounting, cleaning up resources");
      leaveCall();
    };
  }, [leaveCall]);

  // Get supported camera resolutions
  const findSupportedResolutions = useCallback(async () => {
    try {
      console.log("🔍 Finding supported camera resolutions");
      const supportedResolutionsAux: Resolution[] = [];

      for (const resolution of resolutions) {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: { 
              width: { ideal: resolution.width },
              height: { ideal: resolution.height }
            }
          });
          
          // Resolution is supported
          supportedResolutionsAux.push(resolution);
          
          // Clean up the test stream
          stream.getTracks().forEach(track => track.stop());
        } catch (error) {
          // Resolution not supported
        }
      }

      console.log("📊 Supported resolutions:", supportedResolutionsAux);
      return supportedResolutionsAux;
    } catch (error) {
      console.error("Error determining supported resolutions:", error);
      // Return a basic resolution that should work on most devices
      return [{ width: 640, height: 480 }];
    }
  }, []);

  // Get the best camera config
  const getBestCameraConfig = useCallback(async () => {
    try {
      console.log("⚙️ Getting best camera configuration");
      const supportedResolutions = await findSupportedResolutions();
      
      if (supportedResolutions.length === 0) {
        // If no specific resolutions are supported, use a simple config
        return {
          encoderConfig: "360p_1",
          facingMode: "environment" as const,
        };
      }

      // Sort resolutions by area (width * height) descending
      const sortedResolutions = [...supportedResolutions].sort(
        (a, b) => (b.width * b.height) - (a.width * a.height)
      );

      // Choose a resolution that's not too high-end for streaming
      // Prefer 720p or 1080p for best balance of quality and performance
      const targetResolutions = sortedResolutions.filter(
        r => (r.width === 1280 && r.height === 720) || 
             (r.width === 1920 && r.height === 1080)
      );

      const chosenResolution = targetResolutions.length > 0 
        ? targetResolutions[0] 
        : sortedResolutions[0];

      console.log("📹 Selected camera resolution:", chosenResolution);
      return {
        encoderConfig: {
          width: chosenResolution.width,
          height: chosenResolution.height,
          frameRate: 30,
          bitrate: 1500,
        },
        facingMode: "environment" as const,
      };
    } catch (error) {
      console.error("Error getting camera config:", error);
      // Fallback to a safe, low configuration
      return {
        encoderConfig: "180p_1",
        facingMode: "environment" as const,
      };
    }
  }, [findSupportedResolutions]);

  // Initialize camera with progressive fallback
  const turnOnCamera = useCallback(async (): Promise<ILocalVideoTrack | null> => {
    try {
      console.log("🎥 Initializing camera");
      setIsVideoOn(true);
      setLoadingStatus("Initializing camera...");
      
      // Try camera initialization with fallback options
      // First try the optimal config from getBestCameraConfig
      let track: ILocalVideoTrack | null = null;
      let attempts = 0;
      const fallbackConfigs = [
        // First attempt with getBestCameraConfig
        async () => await getBestCameraConfig(), 
        // Second attempt with 720p fixed
        async () => ({ 
          encoderConfig: { 
            width: 1280, 
            height: 720, 
            frameRate: 30, 
            bitrate: 1000 
          },
          facingMode: "environment" as const
        }),
        // Third attempt with 480p fixed
        async () => ({ 
          encoderConfig: { 
            width: 640, 
            height: 480, 
            frameRate: 30, 
            bitrate: 800 
          },
          facingMode: "environment" as const
        }),
        // Fourth attempt with preset
        async () => ({ 
          encoderConfig: "360p_1", 
          facingMode: "environment" as const 
        }),
        // Last attempt with minimal constraints
        async () => ({ facingMode: "environment" as const })
      ];
      
      let errorMessage = "";
      
      // Try each config until one works
      for (const getConfig of fallbackConfigs) {
        try {
          attempts++;
          console.log(`Camera initialization attempt ${attempts}...`);
          const config = await getConfig();
          console.log("Trying camera config:", config);
          
          track = await AgoraRTC.createCameraVideoTrack(config);
          console.log("✅ Camera initialized successfully with attempt", attempts);
          break; // Exit the loop if successful
        } catch (e) {
          errorMessage = e instanceof Error ? e.message : "Unknown camera error";
          console.warn(`Camera initialization attempt ${attempts} failed:`, errorMessage);
          
          // On last attempt, throw the error to be caught by outer catch
          if (attempts >= fallbackConfigs.length) {
            throw e;
          }
          
          // Small delay before next attempt
          await new Promise(resolve => setTimeout(resolve, 300));
        }
      }
      
      if (!track) {
        throw new Error("Failed to initialize camera after multiple attempts");
      }
      
      // Store the successful track
      videoTrack.current = track;
      
      // Play the track
      console.log("Playing camera track to element: camera-video");
      track.play("camera-video");
      
      return track;
    } catch (error) {
      console.error('Error initializing camera after all fallback attempts:', error);
      setIsVideoOn(false);
      toast({
        title: "Camera Error",
        description: "Could not initialize camera. Please check camera permissions and ensure no other app is using it.",
        status: "error",
        duration: 5000,
      });
      return null;
    }
  }, [getBestCameraConfig, toast]);

  // Initialize microphone
  const turnOnMicrophone = useCallback(async (): Promise<ILocalAudioTrack | null> => {
    try {
      console.log("🎤 Initializing microphone");
      setIsAudioOn(true);
      setLoadingStatus("Initializing microphone...");
      
      const track = await AgoraRTC.createMicrophoneAudioTrack();
      audioTrack.current = track;
      
      return track;
    } catch (error) {
      console.error('Error initializing microphone:', error);
      setIsAudioOn(false);
      toast({
        title: "Microphone Error",
        description: "Could not initialize microphone: " + (error instanceof Error ? error.message : "Unknown error"),
        status: "error",
        duration: 5000,
      });
      return null;
    }
  }, [toast]);

  // Handle user publishing media
  const onUserPublish = useCallback(async (user: IAgoraRTCRemoteUser, mediaType: 'audio' | 'video') => {
    try {
      console.log("📢 Remote user published:", user.uid, mediaType);
      setIsVideoMuted(!user.hasVideo);
      setIsAudioMuted(!user.hasAudio);
      
      if (mediaType === "video") {
        console.log("Subscribing to remote video");
        const remoteTrack = await client.subscribe(user, mediaType);
        if (remoteTrack) {
          console.log("Playing remote video to element: remote-video");
          (remoteTrack as IRemoteVideoTrack).play("remote-video");
          setIsRemoteVideoPubed(true);
          setIsVideoPubed(true);
        }
      }
      
      if (mediaType === "audio") {
        console.log("Subscribing to remote audio");
        const remoteTrack = await client.subscribe(user, mediaType);
        if (remoteTrack) {
          console.log("Playing remote audio");
          (remoteTrack as IRemoteAudioTrack).play();
          setIsAudioPubed(true);
        }
      }
    } catch (error) {
      console.error('Error handling remote user media:', error);
    }
  }, []);

  // Handle user unpublishing media
  const onUserUnpublish = useCallback((user: IAgoraRTCRemoteUser) => {
    console.log("📢 Remote user unpublished:", user.uid);
    setIsVideoMuted(!user.hasVideo);
    setIsAudioMuted(!user.hasAudio);
  }, []);

  // Join the Agora channel
  const joinChannel = useCallback(async () => {
    // Don't attempt to join if we don't have a valid channel ID
    if (!channelId) {
      console.error("Cannot join: Missing channel ID");
      setConnectionStatus(prev => ({
        ...prev,
        state: 'error',
        lastError: new Error('Missing channel ID')
      }));
      return false;
    }

    // Don't attempt to join if we're already connected
    if (isJoined) {
      console.log("Already joined to channel");
      return true;
    }

    // Skip if we're already in the process of connecting
    if (client.connectionState === 'CONNECTING') {
      console.log("Already connecting to channel, waiting...");
      return false;
    }

    try {
      // Update UI state
      setConnectionStatus(prev => ({
        ...prev,
        state: 'connecting'
      }));
      setLoadingStatus("Joining video channel...");

      // Double check that we have token and UID
      if (!token.current || typeof uid !== 'number') {
        console.log("❌ Token or UID not available yet:", {
          token: token.current ? "Available" : "Not available",
          uid: uid
        });
        return false;
      }

      console.log(`🔗 Joining channel ${channelId} with UID ${uid} and token ${token.current.substring(0, 20)}...`);

      // Set up event handlers
      client.removeAllListeners("user-published");
      client.removeAllListeners("user-unpublished");
      client.on("user-published", onUserPublish);
      client.on("user-unpublished", onUserUnpublish);

      // Join the channel
      await client.join(
        appid.current,
        channelId,
        token.current,
        uid
      );

      // Update state
      console.log(`✅ Successfully joined channel ${channelId} with UID ${uid}`);
      setAgoraUserId(uid);
      setIsJoined(true);
      setConnectionStatus(prev => ({
        ...prev,
        state: 'connected',
        lastConnected: new Date()
      }));

      return true;
    } catch (error) {
      console.error("❌ Failed to join channel:", error);
      
      // Update error state
      setConnectionStatus(prev => ({
        ...prev,
        state: 'error',
        lastError: error instanceof Error ? error : new Error('Join failed')
      }));

      // Try to leave the channel to clean up
      try {
        if (client.connectionState !== 'DISCONNECTED') {
          await client.leave();
        }
      } catch (leaveErr) {
        console.error("Error leaving after failed join:", leaveErr);
      }

      setIsJoined(false);
      
      // Notify the user
      toast({
        title: "Connection Failed",
        description: error instanceof Error ? error.message : "Failed to connect to video call",
        status: "error",
        duration: 5000,
      });
      
      return false;
    }
  }, [channelId, isJoined, onUserPublish, onUserUnpublish, token, uid, toast]);

  // Publish local tracks
  const publishLocalTracks = useCallback(async () => {
    try {
      console.log("📡 Publishing local tracks");
      setLoadingStatus("Publishing video feed...");

      // Initialize camera
      const videoTrackRes = await turnOnCamera();
      if (videoTrackRes) {
        console.log("📹 Publishing camera track");
        await client.publish(videoTrackRes);
        setIsVideoPubed(true);
      } else {
        console.warn("⚠️ No video track to publish");
      }

      // Initialize microphone
      const audioTrackRes = await turnOnMicrophone();
      if (audioTrackRes) {
        console.log("🎤 Publishing audio track");
        await client.publish(audioTrackRes);
        setIsAudioPubed(true);
      } else {
        console.warn("⚠️ No audio track to publish");
      }

      console.log("✅ Local tracks published successfully");
      return true;
    } catch (error) {
      console.error("❌ Failed to publish local tracks:", error);
      
      // Clean up any created tracks
      await cleanupTracks();
      
      toast({
        title: "Media Error",
        description: "Failed to set up camera or microphone: " + (error instanceof Error ? error.message : "Unknown error"),
        status: "error",
        duration: 5000,
      });
      
      return false;
    }
  }, [turnOnCamera, turnOnMicrophone, cleanupTracks, toast]);

  // Timeout to prevent the component from getting stuck in loading state
  useEffect(() => {
    if (isLoading) {
      const loadingTimeout = setTimeout(() => {
        if (isLoading) {
          console.log("⏰ Loading timeout reached, trying to continue...");
          setIsLoading(false);
          
          // If we're still not connected after timeout, show an error
          if (connectionStatus.state !== 'connected') {
            toast({
              title: "Connection Issue",
              description: "Taking longer than expected to connect. You can try again or continue using limited features.",
              status: "warning",
              duration: 10000,
              isClosable: true,
            });
          }
        }
      }, 20000); // 20 seconds timeout
      
      return () => clearTimeout(loadingTimeout);
    }
    return undefined;
  }, [isLoading, connectionStatus.state, toast]);

  // Initialize the call (join channel and publish)
  const initializeCall = useCallback(async () => {
    try {
      console.log("🚀 Initializing call...");
      setInitializeAttempts(prev => prev + 1);
      
      // Step 1: Join the channel
      const joinSuccess = await joinChannel();
      if (!joinSuccess) {
        console.log("❌ Join was not successful, will try again when token/UID are available");
        return false;
      }
      
      // Step 2: Publish local tracks
      const publishSuccess = await publishLocalTracks();
      if (!publishSuccess) {
        throw new Error("Failed to publish local tracks");
      }
      
      console.log("✅ Call initialized successfully");
      setIsLoading(false);
      return true;
    } catch (error) {
      console.error("❌ Call initialization failed:", error);
      setIsLoading(false);
      
      // Leave the channel on failure
      await leaveCall();
      
      // Show error to user
      toast({
        title: "Video Setup Failed",
        description: error instanceof Error ? error.message : "Failed to set up video call",
        status: "error",
        duration: 5000,
      });
      
      return false;
    }
  }, [joinChannel, publishLocalTracks, leaveCall, toast]);

  // Handle closing the call
  const onCloseCall = useCallback(async () => {
    try {
      console.log("📞 Closing call");
      // Stop recording if active
      if (isRecording) {
        try {
          await stopRecording({
            enterpriseId: enterpriseId!,
            id: channelId,
            agoraUserId: agoraUserId?.toString() || ''
          });
        } catch (error) {
          console.error("Error stopping recording:", error);
        }
      }
      
      // Leave the call
      await leaveCall();
      
      // Call the parent callback
      if (onCloseCallCallback) {
        onCloseCallCallback();
      }
    } catch (error) {
      console.error('Error closing call:', error);
      toast({
        title: "Error",
        description: "There was a problem closing the call",
        status: "error",
        duration: 3000,
      });
      
      // Try to force cleanup
      if (onCloseCallCallback) {
        onCloseCallCallback();
      }
    }
  }, [channelId, enterpriseId, agoraUserId, isRecording, stopRecording, leaveCall, onCloseCallCallback, toast]);

  // Handle recording toggle
  const handleRecord = useCallback(async () => {
    try {
      if (!agoraUserId || !channelId || !enterpriseId) {
        throw new Error('Missing required recording parameters');
      }
      
      const recordingParams: RecordingBody = {
        enterpriseId: enterpriseId,
        id: channelId,
        agoraUserId: agoraUserId.toString()
      };
      
      if (isRecording) {
        await stopRecording(recordingParams);
        toast({
          title: "Recording Stopped",
          status: "info",
          duration: 3000,
        });
      } else {
        await startRecording(recordingParams);
        toast({
          title: "Recording Started",
          status: "info",
          duration: 3000,
        });
      }
      
      setIsRecording(!isRecording);
    } catch (error) {
      console.error('Error handling recording:', error);
      toast({
        title: "Recording Error",
        description: "Failed to start or stop recording",
        status: "error",
        duration: 3000,
      });
    }
  }, [agoraUserId, channelId, enterpriseId, isRecording, startRecording, stopRecording, toast]);

  // Check if we have the necessary info to initialize the call
  const canInitializeCall = useCallback(() => {
    return token.current && typeof uid === 'number' && !isJoined && !isTokenLoading;
  }, [token, uid, isJoined, isTokenLoading]);

  // Periodically try initialization if it hasn't succeeded
  useEffect(() => {
    if (isLoading && canInitializeCall() && initializeAttempts < 3) {
      const retryTimer = setTimeout(() => {
        console.log(`🔄 Retry initialization attempt ${initializeAttempts + 1}...`);
        initializeCall();
      }, 3000 * (initializeAttempts + 1)); // Increasing backoff
      
      return () => clearTimeout(retryTimer);
    }
    return undefined;
  }, [isLoading, canInitializeCall, initializeAttempts, initializeCall]);

  // Try to initialize when tokens are available
  useEffect(() => {
    const tryInitialize = async () => {
      if (canInitializeCall()) {
        console.log("🔑 Token and UID ready, initializing call");
        await initializeCall();
      } else {
        console.log("⏳ Waiting for token and UID...");
      }
    };
    
    tryInitialize();
  }, [canInitializeCall, initializeCall]);

  // Fetch token if needed
  useEffect(() => {
    if (!token.current || !uid) {
      console.log("🔄 Fetching token...");
      setLoadingStatus("Getting access token...");
      fetchToken().catch(error => {
        console.error("Failed to fetch token:", error);
        setConnectionStatus(prev => ({
          ...prev,
          state: 'error',
          lastError: error instanceof Error ? error : new Error("Token fetch failed")
        }));
      });
    }
  }, [fetchToken, token, uid]);

  // Effect to try joining channel again when token and UID become available
  useEffect(() => {
    if (token.current && uid && !isJoined && !isLoading && client.connectionState === 'DISCONNECTED') {
      console.log("🔑 Token and UID available, attempting to join...");
      joinChannel();
    }
  }, [token, uid, isJoined, isLoading, joinChannel]);

  // Handle token error
  useEffect(() => {
    if (tokenError) {
      console.error("❌ Token error:", tokenError);
      toast({
        title: "Connection Error",
        description: "Failed to get access token for video call: " + tokenError.message,
        status: "error",
        duration: 5000,
      });
    }
  }, [tokenError, toast]);

  // Force connecting state to finish loading
  useEffect(() => {
    if (client.connectionState === 'CONNECTED' && isLoading) {
      console.log("🎉 Client connected but loading state is still true, fixing...");
      setIsLoading(false);
    }
  }, [client.connectionState, isLoading]);

  return (
    <VStack
      pos="absolute"
      top={0}
      w="full"
      height={`calc(var(--vh, 1vh) * ${CONFIG.MAIN_HEIGHT})`}
    >
      {/* Loading Spinner */}
      {(isLoading || isTokenLoading) && (
        <Box
          display="flex"
          zIndex={1000000}
          alignItems="center"
          justifyContent="center"
          w="full"
          height={`calc(var(--vh, 1vh) * ${CONFIG.MAIN_HEIGHT})`}
          backgroundColor={COLORS.primary}
          opacity={1}
        >
          <VStack spacing={4}>
            <Spinner size="xl" />
            <Text color="white">
              {loadingStatus}
            </Text>
            <Text color="white" fontSize="xs">
              {initializeAttempts > 0 ? `Attempt ${initializeAttempts}/3` : ''}
            </Text>
            <Button 
              colorScheme="red" 
              size="sm" 
              onClick={onCloseCall} 
              mt={4} 
              display={initializeAttempts >= 2 ? 'block' : 'none'}
            >
              Cancel
            </Button>
          </VStack>
        </Box>
      )}
      
      {/* Debug info when loading */}
      {isLoading && process.env.NODE_ENV === 'development' && (
        <Box
          position="absolute"
          top={4}
          right={4}
          bg="white"
          p={2}
          borderRadius="md"
          zIndex={2000}
          maxW="300px"
          fontSize="xs"
        >
          <Text fontWeight="bold">Debug Info:</Text>
          <Text>Connection: {client.connectionState}</Text>
          <Text>Token: {token.current ? "Available" : "Missing"}</Text>
          <Text>UID: {uid || "Missing"}</Text>
          <Text>Channel: {channelId}</Text>
          <Text>Attempt: {initializeAttempts}</Text>
        </Box>
      )}
      
      {/* Local Video */}
      <Box zIndex={1700} pos="absolute" w="100%">
        <YouVideo isVideoOn={isVideoOn} width="100%" />
      </Box>
      
      {/* Bottom Controls */}
      <BottomActions
        handleRecord={handleRecord}
        isRecording={isRecording}
        isStartRecordingLoading={isStartRecordingLoading}
        isStopRecordingLoading={isStopRecordingLoading}
        onCloseCall={onCloseCall}
        connectionStatus={connectionStatus}
      />
    </VStack>
  );
};

export default VideoRecorder;