import { Box, Button, Spinner, VStack } from "@chakra-ui/react";
import { Editor, Tldraw, createShapeId } from "@tldraw/tldraw";
import { useCallback, useEffect, useRef, useState } from "react";
import { useYjsStore } from "../../hooks/calls/useYjsStore";
import { LaunchCallUsers } from "../../models/CallUsers";

interface WhiteBoardProps {
  roomId: string;
  isTech: boolean;
  isSuccess: boolean;
  callData?: LaunchCallUsers | null; // Made optional
  height: number;
}

const WhiteBoard: React.FC<WhiteBoardProps> = ({
  roomId,
  isTech,
  height,
  isSuccess,
  callData,
}) => {
  const [mounted, setMounted] = useState<boolean>(false);
  const componentRef = useRef<HTMLDivElement>(null);
  const whiteboardHeight = `calc(var(--vh, 1vh) * ${height})` || "700px";
  const renderWhiteboard = true;
  const editorRef = useRef<Editor>();
  const store = useYjsStore({
    roomId: roomId,
    hostUrl: import.meta.env.VITE_APP_WSS_WHITEBOARD || "ws://localhost:1234",
  });
  const shapeId = createShapeId("safeArea");

  const create = useCallback(
    (width: number, height: number) => {
      if (editorRef.current) {
        const exists = editorRef.current?.getShape(shapeId);
        const body = {
          type: "geo",
          id: shapeId,
          x: 0,
          y: 0,
          rotation: 0,
          opacity: 1,
          isLocked: false,
          props: {
            w: width,
            h: height,
            geo: "rectangle",
            color: "red",
            labelColor: "black",
            fill: "solid",
          },
        };
        if (!exists) {
          editorRef.current?.createShape(body);
        } else {
          editorRef.current?.updateShape(body);
        }
      }
    },
    [shapeId]
  );

  const updateAndCreateSafeArea = useCallback(() => {
    if (componentRef.current) {
      const width = componentRef.current.offsetWidth;
      const height = componentRef.current.offsetHeight;
      create(width, height);
    }
  }, [create]);

  const onMounted = useCallback((editor: Editor) => {
    editorRef.current = editor;
    const desiredHeight = window.innerHeight * (height / 100);
    create(window.innerWidth, desiredHeight);
    setMounted(true);
    return () => {
      setMounted(false);
      editorRef.current = undefined;
    };
  }, [create, height]);

  useEffect(() => {
    updateAndCreateSafeArea();
    window.addEventListener("resize", updateAndCreateSafeArea);
    return () => {
      window.removeEventListener("resize", updateAndCreateSafeArea);
    };
  }, [updateAndCreateSafeArea]);

  if (!store) {
    return (
      <VStack w="full" h="full" justify="center">
        <Spinner />
      </VStack>
    );
  }

  return (
    <VStack
      ref={componentRef}
      alignItems={renderWhiteboard ? "flex-end" : "center"}
      w="full"
    >
      <Box
        w="100%"
        display={renderWhiteboard ? "block" : "none"}
        height={whiteboardHeight}
      >
        <Box
          height={whiteboardHeight}
          display={renderWhiteboard ? "block" : "none"}
        >
          <Tldraw
            store={store}
            onMount={onMounted}
            hideUi={isTech}
            // Instead of persistenceKey, use store's features for persistence
            components={{
              // Add any custom components here if needed
            }}
          />
        </Box>
      </Box>
    </VStack>
  );
};

export default WhiteBoard;