import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';

export enum DeviceType {
  RealWear = 'RealWear',
  Vuzix = 'Vuzix',
  AndroidMobile = 'AndroidMobile',
  AndroidTablet = 'AndroidTablet',
  ChromePC = 'ChromePC',
  Other = 'Other'
}

export async function detectDevice(): Promise<DeviceType> {
  // First, check if running in a native platform
  if (!Capacitor.isNativePlatform()) {
    // Enhanced browser detection
    const userAgent = navigator.userAgent.toLowerCase();
    
    console.group('Device Detection Diagnostics');
    console.log('User Agent:', userAgent);
    console.log('Platform:', navigator.platform);
    console.log('Window Screen:', {
      width: window.screen.width,
      height: window.screen.height,
      devicePixelRatio: window.devicePixelRatio
    });
    console.groupEnd();

    // More granular browser/device type detection
    if (userAgent.includes('android')) {
      return DeviceType.AndroidMobile;
    }
    
    if (userAgent.includes('mobile')) {
      return DeviceType.AndroidMobile;
    }

    return DeviceType.ChromePC;
  }

  // Native platform detection (unchanged)
  try {
    const info = await Device.getInfo();
    const model = info.model.toLowerCase();

    if (model.includes('realwear')) {
      return DeviceType.RealWear;
    } else if (model.includes('vuzix')) {
      return DeviceType.Vuzix;
    } else if (info.platform === 'android') {
      // Determine if it's a tablet based on screen size
      const { width, height } = window.screen;
      const screenSize = Math.sqrt(width * width + height * height) / (window.devicePixelRatio || 1);
      const isTablet = screenSize >= 7; // Devices with 7 inch or larger screens are considered tablets
      return isTablet ? DeviceType.AndroidTablet : DeviceType.AndroidMobile;
    }

    return DeviceType.Other;
  } catch (error) {
    console.error('Device detection error:', error);
    return DeviceType.ChromePC;
  }
}

export function isAndroidDevice(deviceType: DeviceType): boolean {
  return [DeviceType.AndroidMobile, DeviceType.AndroidTablet, DeviceType.RealWear, DeviceType.Vuzix].includes(deviceType);
}