import {
  Box,
  Button,
  HStack,
  Spinner,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import AgoraRTC, { 
  IAgoraRTCClient, 
  IAgoraRTCRemoteUser,
  ILocalAudioTrack, 
  ILocalVideoTrack, 
  ICameraVideoTrack,
  IRemoteAudioTrack,
  IRemoteVideoTrack,
  UID,
  IMicrophoneAudioTrack,
  ClientRole,
  ScreenVideoTrackInitConfig,
  CameraVideoTrackInitConfig
} from 'agora-rtc-sdk-ng';
import moment from "moment";
import { LegacyRef, useCallback, useEffect, useRef, useState } from "react";
import { BiMicrophone, BiMicrophoneOff } from "react-icons/bi";
import { FaStop } from "react-icons/fa";
import { FiCamera, FiCameraOff } from "react-icons/fi";
import { IoIosPeople } from "react-icons/io";
import { MdScreenShare, MdStopScreenShare } from "react-icons/md";
import { TiMediaRecord } from "react-icons/ti";
import CallIndicator from "../CallIndicator";
import { useAuth } from "../../hooks/useAuth";
import WhiteBoard from "./WhiteBoard";
import { LaunchCallUsers } from "../../models/CallUsers";
import axios from "axios";
import { SiSourcehut } from "react-icons/si";
import { MdHideSource } from "react-icons/md";
import { CONFIG } from "../../utils/constants";
import { isDesktop } from "../../utils/video";
import { RecordingBody } from "../../models/EnterprisLibrary";
import { useAgoraToken } from '../../hooks/calls/useAgoraToken';
import COLORS from "../../utils/colors";

// Set log level for Agora SDK - reduce to only show important logs
AgoraRTC.setLogLevel(1);

// Define available resolutions to try
const resolutions = [
  { width: 1920, height: 1080 }, // Full HD
  { width: 1280, height: 720 },  // HD
  { width: 1024, height: 768 },  // XGA
  { width: 640, height: 480 },   // VGA
];

// Connection state type
type ConnectionState = 'disconnected' | 'connecting' | 'connected' | 'reconnecting' | 'error';

// Connection status state
interface ConnectionStatus {
  state: ConnectionState;
  lastError?: Error;
  lastConnected?: Date;
}

// Utility function to calculate elapsed time for call duration
const calculateEllapsedTime = (duration: moment.Duration) => {
  if (duration) {
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    const formattedMinutes = (minutes < 10 ? "0" : "") + minutes;
    const formattedSeconds = (seconds < 10 ? "0" : "") + seconds;
    return `${hours}:${formattedMinutes}:${formattedSeconds}`;
  }
  return "";
};

// RemoteVideo component
interface IRemoteVideoProps {
  largeVideoRef: LegacyRef<HTMLVideoElement> | undefined;
  callEnded: boolean;
  otherName: string;
  isVideoMuted: boolean;
  isAudioMuted: boolean;
  isTech: boolean;
  width: string | number;
}

const RemoteVideo: React.FC<IRemoteVideoProps> = ({
  largeVideoRef,
  callEnded,
  otherName,
  isVideoMuted,
  isAudioMuted,
  isTech,
  width
}) => (
  <Box w={width || "100%"} display={callEnded ? "none" : "block"} pos="absolute" top={0}>
    <Box position="relative" w="full">
      <video
        ref={largeVideoRef as React.RefObject<HTMLVideoElement>}
        style={{
          borderWidth: 2,
          borderColor: "black",
          borderStyle: "solid",
          borderRadius: 2,
          background: "black",
        }}
        width={window.innerWidth}
        id="remote-video"
      />
      <HStack pos="absolute" bottom={0} left={0} right={0} justifyContent="center">
        <Box padding={isTech ? 1 : 4} borderRadius={4} backgroundColor="gray.100">
          {isVideoMuted ? <FiCameraOff /> : <FiCamera />}
        </Box>
        <Box padding={isTech ? 1 : 4} borderRadius={4} backgroundColor="gray.100">
          {isAudioMuted ? <BiMicrophoneOff /> : <BiMicrophone />}
        </Box>
      </HStack>
    </Box>
  </Box>
);

// YouVideo component
interface IYouVideoProps {
  isVideoOn: boolean;
  width: number | string;
}

const YouVideo: React.FC<IYouVideoProps> = ({ isVideoOn, width }) => {
  return (
    <VStack
      w={width || "15%"}
      pos="relative"
      top={0}
      left={0}
      zIndex={1}
      width="auto"
      height={`calc(var(--vh, 1vh) * ${CONFIG.MAIN_HEIGHT})`}
    >
      <Text
        zIndex={1}
        position="absolute"
        bottom={0}
        fontSize="sm"
        fontWeight="bold"
        paddingLeft={2}
        paddingRight={2}
        backgroundColor="rgb(255 255 255 / 50%)"
      />
      <video
        style={{
          borderWidth: 2,
          borderColor: "black",
          borderStyle: "solid",
          borderRadius: 2,
          background: "black",
          transform: "none",
        }}
        id="camera-video"
        hidden={!isVideoOn}
      />
    </VStack>
  );
};

// Connection Status Indicator Component
const ConnectionStatusIndicator: React.FC<{ status: ConnectionStatus }> = ({ status }) => {
  const getIndicatorStatus = (): 'connected' | 'disconnected' | 'recording' => {
    switch (status.state) {
      case 'connected':
        return 'connected';
      case 'connecting':
      case 'reconnecting':
        return 'recording'; // Use recording animation for connecting states
      default:
        return 'disconnected';
    }
  };

  return (
    <HStack spacing={2} alignItems="center">
      <CallIndicator status={getIndicatorStatus()} />
      <Text fontSize="sm" color={status.state === 'error' ? 'red.500' : 'inherit'}>
        {status.state.charAt(0).toUpperCase() + status.state.slice(1)}
      </Text>
    </HStack>
  );
};

// Bottom Actions Component
interface IBottomActionsProps {
  handleMuteUnmute: () => void;
  audioTrack: React.MutableRefObject<ILocalAudioTrack | null>;
  videoTrack: React.MutableRefObject<ILocalVideoTrack | null>;
  handleHideShowVideo: () => void;
  handleHideShowYourStream: () => void;
  hideYourVideo: boolean;
  setIsScreenShare: React.Dispatch<React.SetStateAction<boolean>>;
  isScreenShare: boolean;
  meetingEllapsedTime: string | undefined;
  isTech: boolean;
  onCloseCall: () => void;
  handleRecord: () => void;
  isRecording: boolean;
  connectionStatus: ConnectionStatus;
  isRecordingLoading: boolean;
}

const BottomActions: React.FC<IBottomActionsProps> = ({
  handleMuteUnmute,
  audioTrack,
  videoTrack,
  handleHideShowVideo,
  handleHideShowYourStream,
  hideYourVideo,
  setIsScreenShare,
  isScreenShare,
  meetingEllapsedTime,
  isTech,
  onCloseCall,
  handleRecord,
  isRecording,
  connectionStatus,
  isRecordingLoading,
}) => (
  <HStack
    w="full"
    alignSelf="flex-end"
    justifyContent="space-between"
    pos="absolute"
    bottom={0}
    left={0}
    right={0}
    paddingX={5}
    zIndex={1800}
  >
    <HStack gap={0} justifyContent="center" backgroundColor="white">
      <ConnectionStatusIndicator status={connectionStatus} />
      <Button
        size="sm"
        padding={[0, "sm", "md"]}
        paddingInlineStart={0}
        paddingInlineEnd={0}
        variant="ghost"
        onClick={handleMuteUnmute}
        title={audioTrack.current?.muted ? "Unmute" : "Mute"}
        isDisabled={connectionStatus.state !== 'connected'}
      >
        {audioTrack.current?.muted ? <BiMicrophoneOff /> : <BiMicrophone />}
      </Button>
      <Button
        size="sm"
        padding={[0, "sm", "md"]}
        paddingInlineStart={0}
        paddingInlineEnd={0}
        variant="ghost"
        title={videoTrack.current?.muted ? "Start" : "Hide"}
        onClick={handleHideShowVideo}
        isDisabled={connectionStatus.state !== 'connected'}
      >
        {videoTrack.current?.muted ? <FiCameraOff /> : <FiCamera />}
      </Button>
      {!isTech && (
        <Button
          size="sm"
          onClick={() => {
            setIsScreenShare(!isScreenShare);
          }}
          variant="ghost"
          title={isScreenShare ? "Stop Sharing" : "Start Sharing"}
          isDisabled={connectionStatus.state !== 'connected'}
        >
          {isScreenShare ? <MdStopScreenShare /> : <MdScreenShare />}
        </Button>
      )}
      <Button
        size="sm"
        onClick={handleRecord}
        variant="ghost"
        title={isRecording ? "Stop Recording" : "Start Recording"}
        isDisabled={connectionStatus.state !== 'connected' || isRecordingLoading}
      >
        {isRecording ? (
          <HStack>
            <FaStop size={25} />
            <CallIndicator status="recording" />
          </HStack>
        ) : (
          <TiMediaRecord size={25} />
        )}
      </Button>
      <Button
        size="sm"
        onClick={handleHideShowYourStream}
        variant="ghost"
        title={hideYourVideo ? "Show cameras" : "Hide cameras"}
      >
        {hideYourVideo ? <MdHideSource size={25} /> : <SiSourcehut size={20} />}
      </Button>
    </HStack>
    <HStack backgroundColor="white">
      <Text fontSize="sm" fontWeight="bold">
        Duration:
      </Text>
      <Text fontSize="sm">{meetingEllapsedTime || "00:00:00"}</Text>
    </HStack>
    <HStack>
      <Button size="sm" colorScheme="red" onClick={onCloseCall}>
        Close
      </Button>
    </HStack>
  </HStack>
);

// Create a single Agora client instance to reuse
const client: IAgoraRTCClient = AgoraRTC.createClient({
  mode: "rtc",
  codec: "vp8"
});

// VideoPlayer component props interface
interface IVideoPlayer {
  startCall: (callId: string) => Promise<LaunchCallUsers>;
  setCallEnded: (ended: boolean) => void;
  closeCall: (callId: string) => Promise<void>;
  callEnded: boolean;
  isCallStarting: boolean;
  channelId: string | null;
  isErrorStarting: boolean;
  errorStarting: Error | string | null;
  isSuccessStarting: boolean;
  isTech: boolean;
  callData: LaunchCallUsers | null | undefined;
  callId: string | null;
  startRecording?: (params: RecordingBody) => Promise<void>;
  stopRecording?: (params: RecordingBody) => Promise<void>;
  isStartRecordingLoading?: boolean;
  isStopRecordingLoading?: boolean;
}

// Main VideoPlayer component
const VideoPlayer: React.FC<IVideoPlayer> = ({
  startCall,
  setCallEnded,
  closeCall,
  callEnded,
  isCallStarting,
  channelId,
  isErrorStarting,
  errorStarting,
  isSuccessStarting,
  isTech,
  callData,
  callId,
  startRecording,
  stopRecording,
  isStartRecordingLoading = false,
  isStopRecordingLoading = false
}) => {
  // Early return if missing required parameters
  if (!channelId || !callId) {
    console.error('Missing required channel or call ID');
    return null;
  }

  // Hooks and state
  const { enterpriseId, user, socket } = useAuth();
  const toast = useToast();
  const { token, uid, fetchToken, tokenError, isTokenLoading } = useAgoraToken(channelId);
  const appid = useRef<string>(import.meta.env.VITE_APP_AGORA_VIDEO || '');
  
  // References for media elements and tracks
  const largeVideoRef = useRef<HTMLVideoElement>(null);
  const videoTrack = useRef<ILocalVideoTrack | null>(null);
  const audioTrack = useRef<ILocalAudioTrack | null>(null);
  
  // UI and call state
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loadingStatus, setLoadingStatus] = useState<string>("Initializing...");
  const [isVideoOn, setIsVideoOn] = useState<boolean>(false);
  const [isAudioOn, setIsAudioOn] = useState<boolean>(false);
  const [isVideoPubed, setIsVideoPubed] = useState<boolean>(false);
  const [isAudioPubed, setIsAudioPubed] = useState<boolean>(false);
  const [isRemoteVideoPubed, setIsRemoteVideoPubed] = useState<boolean>(false);
  const [isVideoMuted, setIsVideoMuted] = useState<boolean>(false);
  const [isAudioMuted, setIsAudioMuted] = useState<boolean>(false);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [isScreenShare, setIsScreenShare] = useState<boolean>(false);
  const [hideYourVideo, setHideYourVideo] = useState<boolean>(false);
  const [meetingEllapsedTime, setMeetingEllapsedTime] = useState<string>();
  const [isJoined, setIsJoined] = useState<boolean>(false);
  const [otherName, setOtherName] = useState<string>("");
  const [launchedAt, setLaunchedAt] = useState<Date>();
  const [agoraUserId, setAgoraUserId] = useState<UID | null>(null);
  
  // Connection management state
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>({
    state: 'disconnected'
  });
  const [connectionFailed, setConnectionFailed] = useState<boolean>(false);
  const [initializeAttempts, setInitializeAttempts] = useState<number>(0);
  const maxInitializeAttempts = 3;
  const initializeAttemptsRef = useRef<number>(0);
  
  // Debug state
  const [debugInfo, setDebugInfo] = useState<string>("");

  // Set up logging for important state changes
  useEffect(() => {
    console.log('💡 Debug - Connection status:', connectionStatus.state);
    console.log('💡 Debug - Connection state:', client.connectionState);
    console.log('💡 Debug - UID:', uid);
    console.log('💡 Debug - Token:', token.current ? token.current.substring(0, 20) + '...' : 'Not Available');
    console.log('💡 Debug - isLoading:', isLoading);
    console.log('💡 Debug - isTokenLoading:', isTokenLoading);
    console.log('💡 Debug - isJoined:', isJoined);
    console.log('💡 Debug - Initialize attempts:', initializeAttempts);
  }, [connectionStatus.state, uid, token, isLoading, isTokenLoading, isJoined, initializeAttempts]);

  // Connection state monitoring
  useEffect(() => {
    const handleConnectionStateChange = (curState: string) => {
      console.log("🔄 Agora connection state changed:", curState);
      
      let newState: ConnectionState;
      switch (curState) {
        case 'CONNECTING':
          newState = 'connecting';
          setLoadingStatus("Connecting to video service...");
          break;
        case 'CONNECTED':
          newState = 'connected';
          setLoadingStatus("Connected!");
          setIsLoading(false); // Ensure loading is finished when connected
          break;
        case 'DISCONNECTED':
          newState = 'disconnected';
          setLoadingStatus("Disconnected from video service");
          break;
        case 'RECONNECTING':
          newState = 'reconnecting';
          setLoadingStatus("Reconnecting to video service...");
          break;
        default:
          newState = 'error';
          setLoadingStatus("Connection error");
      }

      setConnectionStatus(prev => ({
        ...prev,
        state: newState,
        lastConnected: newState === 'connected' ? new Date() : prev.lastConnected
      }));
    };

    console.log("⚡ Setting up connection state handler");
    client.on('connection-state-change', handleConnectionStateChange);

    return () => {
      console.log("⚡ Removing connection state handler");
      client.off('connection-state-change', handleConnectionStateChange);
    };
  }, []);

  // Cleanup tracks helper
  const cleanupTracks = useCallback(async () => {
    try {
      console.log("🧹 Cleaning up tracks");
      if (videoTrack.current) {
        videoTrack.current.stop();
        videoTrack.current.close();
        videoTrack.current = null;
      }
      if (audioTrack.current) {
        audioTrack.current.stop();
        audioTrack.current.close();
        audioTrack.current = null;
      }
      setIsVideoOn(false);
      setIsAudioOn(false);
    } catch (error) {
      console.error('Error cleaning up tracks:', error);
    }
  }, []);

  // Leave channel and clean up
  const leaveCall = useCallback(async () => {
    try {
      console.log("📞 Leaving call");
      await cleanupTracks();
      
      if (client.connectionState !== 'DISCONNECTED') {
        console.log("🔌 Leaving Agora channel...");
        await client.leave();
      }
      
      setIsJoined(false);
      setIsVideoPubed(false);
      setIsAudioPubed(false);
      setConnectionStatus({
        state: 'disconnected'
      });
    } catch (error) {
      console.error('Error leaving call:', error);
    }
  }, [cleanupTracks]);

  // Handle cleanup on component unmount
  useEffect(() => {
    const handleBeforeUnload = () => {
      leaveCall();
    };
  
    window.addEventListener("beforeunload", handleBeforeUnload);
  
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      console.log("🧨 Component unmounting, cleaning up resources");
      leaveCall();
    };
  }, [leaveCall]);

  // Function to get all video tracks with their resolutions
  const findSupportedResolutions = useCallback(async () => {
    try {
      console.log("🔍 Finding supported camera resolutions");
      const supportedResolutionsAux = [];

      for (const resolution of resolutions) {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: { 
              width: { ideal: resolution.width },
              height: { ideal: resolution.height }
            }
          });
          
          // Resolution is supported
          supportedResolutionsAux.push(resolution);
          
          // Clean up the test stream
          stream.getTracks().forEach(track => track.stop());
        } catch (error) {
          // Resolution not supported
        }
      }

      console.log("📊 Supported resolutions:", supportedResolutionsAux);
      return supportedResolutionsAux;
    } catch (error) {
      console.error("Error determining supported resolutions:", error);
      // Return a basic resolution that should work on most devices
      return [{ width: 640, height: 480 }];
    }
  }, []);

  // Get the best camera config
  const handleResolutions = useCallback((supportedResolutions: { width: number; height: number }[]) => {
    if (!supportedResolutions || supportedResolutions.length === 0) return null;
    
    console.log('🔍 Finding best resolution for device. Desktop:', isDesktop);
    
    const DESIRED_HMT1 = { width: 2560, height: 1440 };
    
    // Case for HTM1
    if (!isDesktop && supportedResolutions.find(
        sr => sr.height === DESIRED_HMT1.height && sr.width === DESIRED_HMT1.width
      )) {
      console.log('📊 Selected HTM1 resolution:', DESIRED_HMT1);
      return DESIRED_HMT1;
    } 
    
    // Try full HD
    if (supportedResolutions.find(sr => sr.height === 1080 && sr.width === 1920)) {
      console.log('📊 Selected Full HD resolution: 1920x1080');
      return { width: 1920, height: 1080 };
    } 
    
    // Fall back to best available
    console.log('📊 Selected fallback resolution:', supportedResolutions[0]);
    return supportedResolutions[0];
  }, []);

  // Initialize camera
  const turnOnCamera = useCallback(async (flag: boolean, screenShare: boolean): Promise<ILocalVideoTrack | null> => {
    try {
      console.log("🎥 Initializing camera, screenShare:", screenShare);
      setIsVideoOn(flag);
      setLoadingStatus("Initializing camera...");
      
      if (screenShare) {
        // Screen sharing configuration
        const screenConfig: ScreenVideoTrackInitConfig = {
          encoderConfig: {
            width: 1920,
            height: 1080,
            frameRate: 30,
            bitrateMin: 600,
            bitrateMax: 2000,
          },
        };

        console.log("📺 Creating screen share track");
        const screenTrack = await AgoraRTC.createScreenVideoTrack(
          screenConfig,
          "disable"
        );
        const track = Array.isArray(screenTrack) ? screenTrack[0] : screenTrack;
        videoTrack.current = track;
      } else {
        // Camera configuration
        const supportedResolutions = await findSupportedResolutions();
        const calculatedRes = handleResolutions(supportedResolutions);
        
        let videoConfig: CameraVideoTrackInitConfig = {
          facingMode: "environment" as const,
        };
        
        if (calculatedRes) {
          videoConfig.encoderConfig = {
            width: { ideal: calculatedRes.width },
            height: { ideal: calculatedRes.height },
            frameRate: { min: 15, max: 30, ideal: 30 },
            bitrateMax: 5000,
            bitrateMin: 2000,
          };
        }

        console.log("📹 Creating camera track with config:", videoConfig);
        const track = await AgoraRTC.createCameraVideoTrack(videoConfig);
        videoTrack.current = track;
      }
      
      // Play the track if created successfully
      if (videoTrack.current) {
        console.log("▶️ Playing video track to local view");
        videoTrack.current.play("camera-video");
        
        // Apply styling to the video element
        const videoRef = document.getElementById("camera-video");
        if (videoRef) {
          videoRef.style.transform = "none";
          videoRef.style.objectFit = "cover";
          videoRef.style.width = "100%";
          videoRef.style.height = `${
            (CONFIG.MAIN_HEIGHT / 100) * window.innerHeight
          }px`;
        }
      }
      
      return videoTrack.current;
    } catch (error) {
      console.error('❌ Error initializing camera:', error);
      setIsVideoOn(false);
      toast({
        title: "Camera Error",
        description: "Could not initialize camera: " + (error instanceof Error ? error.message : "Unknown error"),
        status: "error",
        duration: 5000,
      });
      return null;
    }
  }, [findSupportedResolutions, handleResolutions, toast]);

  // Initialize microphone
  const turnOnMicrophone = useCallback(async (flag: boolean): Promise<ILocalAudioTrack | null> => {
    try {
      console.log("🎤 Initializing microphone");
      setIsAudioOn(flag);
      setLoadingStatus("Initializing microphone...");
      
      const track = await AgoraRTC.createMicrophoneAudioTrack();
      audioTrack.current = track;
      
      return track;
    } catch (error) {
      console.error('❌ Error initializing microphone:', error);
      setIsAudioOn(false);
      toast({
        title: "Microphone Error",
        description: "Could not initialize microphone: " + (error instanceof Error ? error.message : "Unknown error"),
        status: "error",
        duration: 5000,
      });
      return null;
    }
  }, [toast]);

  // Handle user publishing media
  const onUserPublish = useCallback(async (user: IAgoraRTCRemoteUser, mediaType: 'audio' | 'video') => {
    try {
      console.log("📢 Remote user published:", user.uid, mediaType);
      setIsVideoMuted(!user.hasVideo);
      setIsAudioMuted(!user.hasAudio);
      
      if (mediaType === "video") {
        console.log("Subscribing to remote video");
        const remoteTrack = await client.subscribe(user, mediaType);
        if (remoteTrack) {
          console.log("Playing remote video to element: remote-video");
          (remoteTrack as IRemoteVideoTrack).play("remote-video");
          setIsRemoteVideoPubed(true);
          setIsVideoPubed(true);
        }
      }
      
      if (mediaType === "audio") {
        console.log("Subscribing to remote audio");
        const remoteTrack = await client.subscribe(user, mediaType);
        if (remoteTrack) {
          console.log("Playing remote audio");
          (remoteTrack as IRemoteAudioTrack).play();
          setIsAudioPubed(true);
        }
      }
    } catch (error) {
      console.error('Error handling remote user media:', error);
    }
  }, []);

  // Handle user unpublishing media
  const onUserUnpublish = useCallback((user: IAgoraRTCRemoteUser) => {
    console.log("📢 Remote user unpublished:", user.uid);
    setIsVideoMuted(!user.hasVideo);
    setIsAudioMuted(!user.hasAudio);
    
    if (!user.audioTrack && !user.videoTrack) {
      setIsRemoteVideoPubed(false);
      setIsVideoPubed(false);
      setIsAudioPubed(false);
    }
  }, []);

  // Join the Agora channel
  const joinChannel = useCallback(async () => {
    try {
      console.log("🔗 Joining channel", channelId);
      
      // Don't attempt to join if we don't have a valid channel ID
      if (!channelId) {
        console.error("Cannot join: Missing channel ID");
        setConnectionStatus(prev => ({
          ...prev,
          state: 'error',
          lastError: new Error('Missing channel ID')
        }));
        return false;
      }

      // Update UI state
      setConnectionStatus(prev => ({
        ...prev,
        state: 'connecting'
      }));
      setLoadingStatus("Joining video channel...");

      // Double check that we have token and UID
      if (!token.current || typeof uid !== 'number') {
        console.log("❌ Token or UID not available yet:", {
          token: token.current ? "Available" : "Not available",
          uid: uid
        });
        return false;
      }

      console.log(`🔗 Joining channel ${channelId} with UID ${uid} and token ${token.current.substring(0, 20)}...`);

      // Set up event handlers
      client.removeAllListeners("user-published");
      client.removeAllListeners("user-unpublished");
      client.on("user-published", onUserPublish);
      client.on("user-unpublished", onUserUnpublish);

      // Join the channel
      const joinedUid = await client.join(
        appid.current,
        channelId,
        token.current,
        uid
      );

      // Update state
      console.log(`✅ Successfully joined channel ${channelId} with UID ${joinedUid}`);
      setAgoraUserId(joinedUid);
      setIsJoined(true);
      setConnectionStatus(prev => ({
        ...prev,
        state: 'connected',
        lastConnected: new Date()
      }));

      return true;
    } catch (error) {
      console.error("❌ Failed to join channel:", error);
      
      // Update error state
      setConnectionStatus(prev => ({
        ...prev,
        state: 'error',
        lastError: error instanceof Error ? error : new Error('Join failed')
      }));

      // Try to leave the channel to clean up
      try {
        if (client.connectionState !== 'DISCONNECTED') {
          await client.leave();
        }
      } catch (leaveErr) {
        console.error("Error leaving after failed join:", leaveErr);
      }

      setIsJoined(false);
      
      // Notify the user
      toast({
        title: "Connection Failed",
        description: error instanceof Error ? error.message : "Failed to connect to video call",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      
      return false;
    }
  }, [channelId, onUserPublish, onUserUnpublish, token, uid, toast]);

  // Publish local tracks
  const publishLocalTracks = useCallback(async () => {
    try {
      console.log("📡 Publishing local tracks");
      setLoadingStatus("Publishing video feed...");

      // Initialize camera
      const videoTrackRes = await turnOnCamera(true, isScreenShare);
      if (videoTrackRes) {
        console.log("📹 Publishing camera track");
        await client.publish(videoTrackRes);
        setIsVideoPubed(true);
      } else {
        console.warn("⚠️ No video track to publish");
      }

      // Initialize microphone
      const audioTrackRes = await turnOnMicrophone(true);
      if (audioTrackRes) {
        console.log("🎤 Publishing audio track");
        await client.publish(audioTrackRes);
        setIsAudioPubed(true);
      } else {
        console.warn("⚠️ No audio track to publish");
      }

      console.log("✅ Local tracks published successfully");
      return true;
    } catch (error) {
      console.error("❌ Failed to publish local tracks:", error);
      
      // Clean up any created tracks
      await cleanupTracks();
      
      toast({
        title: "Media Error",
        description: "Failed to set up camera or microphone: " + (error instanceof Error ? error.message : "Unknown error"),
        status: "error",
        duration: 5000,
      });
      
      return false;
    }
  }, [turnOnCamera, turnOnMicrophone, isScreenShare, cleanupTracks, toast]);

  // Initialize the call (join channel and publish)
  const initializeCall = useCallback(async () => {
    try {
      console.log("🚀 Initializing call...");
      setInitializeAttempts(prev => prev + 1);
      initializeAttemptsRef.current += 1;
      
      // Step 1: Join the channel
      const joinSuccess = await joinChannel();
      if (!joinSuccess) {
        console.log("❌ Join was not successful, will try again when token/UID are available");
        return false;
      }
      
      // Step 2: Publish local tracks
      const publishSuccess = await publishLocalTracks();
      if (!publishSuccess) {
        throw new Error("Failed to publish local tracks");
      }
      
      console.log("✅ Call initialized successfully");
      setIsLoading(false);
      return true;
    } catch (error) {
      console.error("❌ Call initialization failed:", error);
      setIsLoading(false);
      
      // Leave the channel on failure
      await leaveCall();
      
      // Show error to user
      toast({
        title: "Video Setup Failed",
        description: error instanceof Error ? error.message : "Failed to set up video call",
        status: "error",
        duration: 5000,
      });
      
      return false;
    }
  }, [joinChannel, publishLocalTracks, leaveCall, toast]);

  // Handle closing the call
  const onCloseCall = useCallback(async () => {
    try {
      console.log("📞 Closing call");
      
      // Stop recording if active
      if (isRecording && stopRecording && agoraUserId) {
        try {
          await stopRecording({
            enterpriseId: enterpriseId!,
            id: channelId,
            agoraUserId: agoraUserId.toString()
          });
        } catch (error) {
          console.error("Error stopping recording:", error);
        }
      }
      
      // Leave the call
      await leaveCall();
      
      // Call the parent callback
      if (closeCall) {
        await closeCall(channelId);
      }
      
      setCallEnded(true);
    } catch (error) {
      console.error('Error closing call:', error);
      toast({
        title: "Error",
        description: "There was a problem closing the call",
        status: "error",
        duration: 3000,
      });
      
      // Try to force cleanup
      setCallEnded(true);
    }
  }, [channelId, enterpriseId, agoraUserId, isRecording, stopRecording, leaveCall, closeCall, setCallEnded, toast]);

  // Handle recording toggle
  const handleRecord = useCallback(async () => {
    try {
      if (!agoraUserId || !channelId || !enterpriseId) {
        throw new Error('Missing required recording parameters');
      }
      
      if (!startRecording || !stopRecording) {
        console.warn("Recording functions not provided");
        toast({
          title: "Recording Not Available",
          description: "Recording functionality is not available for this call",
          status: "warning",
          duration: 3000,
        });
        return;
      }
      
      const recordingParams: RecordingBody = {
        enterpriseId: enterpriseId,
        id: channelId,
        agoraUserId: agoraUserId.toString()
      };
      
      if (isRecording) {
        await stopRecording(recordingParams);
        toast({
          title: "Recording Stopped",
          status: "info",
          duration: 3000,
        });
      } else {
        await startRecording(recordingParams);
        toast({
          title: "Recording Started",
          status: "info",
          duration: 3000,
        });
      }
      
      setIsRecording(!isRecording);
    } catch (error) {
      console.error('Error handling recording:', error);
      toast({
        title: "Recording Error",
        description: "Failed to start or stop recording",
        status: "error",
        duration: 3000,
      });
    }
  }, [agoraUserId, channelId, enterpriseId, isRecording, startRecording, stopRecording, toast]);

  // User control functions
  const handleMuteUnmute = useCallback(() => {
    if (audioTrack.current) {
      audioTrack.current.setMuted(!audioTrack.current.muted);
    }
  }, []);

  const handleHideShowVideo = useCallback(() => {
    if (videoTrack.current) {
      videoTrack.current.setMuted(!videoTrack.current.muted);
    }
  }, []);

  const handleHideShowYourStream = useCallback(() => {
    setHideYourVideo(!hideYourVideo);
  }, [hideYourVideo]);

  // Check if we have the necessary info to initialize the call
  const canInitializeCall = useCallback(() => {
    return token.current && 
           typeof uid === 'number' && 
           !isJoined && 
           !isTokenLoading &&
           !connectionFailed &&
           initializeAttemptsRef.current < maxInitializeAttempts;
  }, [token, uid, isJoined, isTokenLoading, connectionFailed]);

  // Timeout to prevent the component from getting stuck in loading state
  useEffect(() => {
    if (isLoading) {
      const loadingTimeout = setTimeout(() => {
        if (isLoading) {
          console.log("⏰ Loading timeout reached, trying to continue...");
          setIsLoading(false);
          
          // If we're still not connected after timeout, show an error
          if (connectionStatus.state !== 'connected') {
            toast({
              title: "Connection Issue",
              description: "Taking longer than expected to connect. You can try again or continue using limited features.",
              status: "warning",
              duration: 10000,
              isClosable: true,
            });
          }
        }
      }, 20000); // 20 seconds timeout
      
      return () => clearTimeout(loadingTimeout);
    }
    return undefined;
  }, [isLoading, connectionStatus.state, toast]);

  // Fetch token if needed
  useEffect(() => {
    if (!token.current || !uid) {
      console.log("🔄 Fetching token...");
      setLoadingStatus("Getting access token...");
      fetchToken().catch(error => {
        console.error("Failed to fetch token:", error);
        setConnectionStatus(prev => ({
          ...prev,
          state: 'error',
          lastError: error instanceof Error ? error : new Error("Token fetch failed")
        }));
      });
    }
  }, [fetchToken, token, uid]);

  // Try to initialize when tokens are available
  useEffect(() => {
    const tryInitialize = async () => {
      if (canInitializeCall()) {
        console.log("🔑 Token and UID ready, initializing call");
        await initializeCall();
      } else {
        console.log("⏳ Waiting for token and UID...");
      }
    };
    
    tryInitialize();
  }, [canInitializeCall, initializeCall]);

  // Periodically try initialization if it hasn't succeeded
  useEffect(() => {
    if (isLoading && canInitializeCall() && initializeAttempts < maxInitializeAttempts) {
      const retryTimer = setTimeout(() => {
        console.log(`🔄 Retry initialization attempt ${initializeAttempts + 1}...`);
        initializeCall();
      }, 3000 * (initializeAttempts + 1)); // Increasing backoff
      
      return () => clearTimeout(retryTimer);
    }
    return undefined;
  }, [isLoading, canInitializeCall, initializeAttempts, initializeCall, maxInitializeAttempts]);

  // Effect to try joining channel again when token and UID become available
  useEffect(() => {
    if (token.current && uid && !isJoined && !isLoading && client.connectionState === 'DISCONNECTED') {
      console.log("🔑 Token and UID available, attempting to join...");
      joinChannel();
    }
  }, [token, uid, isJoined, isLoading, joinChannel]);

  // Handle token error
  useEffect(() => {
    if (tokenError) {
      console.error("❌ Token error:", tokenError);
      toast({
        title: "Connection Error",
        description: "Failed to get access token for video call: " + tokenError.message,
        status: "error",
        duration: 5000,
      });
    }
  }, [tokenError, toast]);

  // Force connecting state to finish loading
  useEffect(() => {
    if (client.connectionState === 'CONNECTED' && isLoading) {
      console.log("🎉 Client connected but loading state is still true, fixing...");
      setIsLoading(false);
    }
  }, [client.connectionState, isLoading]);

  // Call startup effect - try to fetch call data and start call
  useEffect(() => {
    if (!callId || !channelId || !enterpriseId || connectionFailed) {
      return;
    }
    
    const startCallProcess = async () => {
      try {
        setIsLoading(true);
        setLoadingStatus("Starting call...");
        
        // Fetch call data from server
        const data = await startCall(channelId);
        
        // Set up call information
        const launchedTime = data?.launchedAt ? new Date(data.launchedAt) : new Date();
        const otherPersonName = data?.attendees?.find(
          (attendee) => attendee._id !== user?._id
        )?.name || "";
        
        setOtherName(otherPersonName);
        setLaunchedAt(launchedTime);
        
        console.log(`🔍 Call data loaded. Other participant: ${otherPersonName}`);
      } catch (error) {
        console.error("❌ Error fetching call data:", error);
        
        if (axios.isAxiosError(error)) {
          const msg = error.response?.data?.message || error.message;
          toast({ 
            title: "Call Initialization Failed", 
            description: msg,
            status: "error" 
          });
        } else {
          toast({ 
            title: "Cannot launch video call", 
            status: "error" 
          });
        }
        
        setConnectionFailed(true);
        setIsLoading(false);
      }
    };

    startCallProcess();
  }, [callId, channelId, enterpriseId, user, startCall, connectionFailed, toast]);

  // Set up whiteboard visibility based on call data
  useEffect(() => {
    if (callData?.snapshots?.length) {
      setHideYourVideo(true);
    }
  }, [callData]);

  // Handle socket events for snapshots
  useEffect(() => {
    const handleCaptured = () => {
      setHideYourVideo(true);
    };
    
    if (socket) {
      socket.on("captured", handleCaptured);
      
      return () => {
        socket.off("captured", handleCaptured);
      };
    }
  }, [socket]);

  // Set up call duration timer
  useEffect(() => {
    if (launchedAt) {
      const intervalId = setInterval(() => {
        const now = moment();
        const duration = moment.duration(now.diff(launchedAt));
        const ellapsedTime = calculateEllapsedTime(duration);
        setMeetingEllapsedTime(ellapsedTime);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [launchedAt]);

  return (
    <VStack w="full">
      {/* Loading Spinner */}
      {(isLoading || isTokenLoading) && (
        <Box
          display="flex"
          zIndex={1000000}
          alignItems="center"
          justifyContent="center"
          w="full"
          height={`calc(var(--vh, 1vh) * ${CONFIG.MAIN_HEIGHT})`}
          backgroundColor={COLORS.primary}
          opacity={1}
        >
          <VStack spacing={4}>
            <Spinner size="xl" />
            <Text color="white">
              {loadingStatus}
            </Text>
            <Text color="white" fontSize="xs">
              {initializeAttempts > 0 ? `Attempt ${initializeAttempts}/${maxInitializeAttempts}` : ''}
            </Text>
            <Button 
              colorScheme="red" 
              size="sm" 
              onClick={onCloseCall} 
              mt={4} 
              display={initializeAttempts >= 2 ? 'block' : 'none'}
            >
              Cancel
            </Button>
          </VStack>
        </Box>
      )}
      
      {/* Debug info when loading */}
      {isLoading && process.env.NODE_ENV === 'development' && (
        <Box
          position="absolute"
          top={4}
          right={4}
          bg="white"
          p={2}
          borderRadius="md"
          zIndex={2000}
          maxW="300px"
          fontSize="xs"
        >
          <Text fontWeight="bold">Debug Info:</Text>
          <Text>Connection: {client.connectionState}</Text>
          <Text>Token: {token.current ? "Available" : "Missing"}</Text>
          <Text>UID: {uid || "Missing"}</Text>
          <Text>Channel: {channelId}</Text>
          <Text>Attempt: {initializeAttempts}</Text>
        </Box>
      )}
      
      {/* Main Content Area */}
      <Box w="full" pos="relative">
        {/* Remote Video */}
        <HStack>
          <Box display={hideYourVideo || !isRemoteVideoPubed ? "none" : "block"}>
            <RemoteVideo
              callEnded={callEnded}
              isAudioMuted={isAudioMuted}
              isVideoMuted={isVideoMuted}
              largeVideoRef={largeVideoRef}
              otherName={otherName}
              width="15%"
              isTech={isTech}
            />
          </Box>
        </HStack>
        
        {/* Local Video */}
        <Box zIndex={1700} pos="absolute" w="100%">
          <YouVideo isVideoOn={isVideoOn} width="100%" />
        </Box>
        
        {/* Whiteboard */}
        <Box pos="relative">
          <WhiteBoard
            roomId={callId || ""}
            isSuccess={isSuccessStarting}
            isTech={isTech}
            callData={callData}
            height={CONFIG.MAIN_HEIGHT}
          />
        </Box>
      </Box>
      
      {/* Bottom Controls */}
      {isSuccessStarting && (
        <BottomActions
          handleMuteUnmute={handleMuteUnmute}
          audioTrack={audioTrack}
          videoTrack={videoTrack}
          handleHideShowVideo={handleHideShowVideo}
          handleHideShowYourStream={handleHideShowYourStream}
          hideYourVideo={hideYourVideo}
          setIsScreenShare={setIsScreenShare}
          isScreenShare={isScreenShare}
          meetingEllapsedTime={meetingEllapsedTime}
          isTech={isTech}
          onCloseCall={onCloseCall}
          handleRecord={handleRecord}
          isRecording={isRecording}
          connectionStatus={connectionStatus}
          isRecordingLoading={isStartRecordingLoading || isStopRecordingLoading}
        />
      )}
    </VStack>
  );
};

export default VideoPlayer;