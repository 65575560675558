import { setupVuzixVoiceCommands, startVuzixListening, stopVuzixListening, addVuzixCommand } from './VuzixSpeechRecognition';
import { setupRealWearVoiceCommands, startRealWearListening, stopRealWearListening, addRealWearCommand } from './RealWearSpeechRecognition';
import CustomSpeechRecognition from './SpeechRecognitionResult';
import { DeviceType } from './DeviceDetection';

interface SpeechRecognitionResultOutput {
  transcript: string;
  isFinal: boolean;
}

let customSpeechRecognition: CustomSpeechRecognition | null = null;

export async function setupVoiceCommands(deviceType: DeviceType) {
  switch (deviceType) {
    case DeviceType.Vuzix:
      await setupVuzixVoiceCommands();
      break;
    case DeviceType.RealWear:
      await setupRealWearVoiceCommands();
      break;
    default:
      customSpeechRecognition = new CustomSpeechRecognition();
      if (!customSpeechRecognition.isSupported()) {
        throw new Error('Speech recognition is not supported on this device');
      }
  }
}

export function startListening(
  deviceType: DeviceType, 
  onResult: (result: string) => void, 
  onError: (error: Error) => void
) {
  switch (deviceType) {
    case DeviceType.Vuzix:
      return startVuzixListening();
    case DeviceType.RealWear:
      return startRealWearListening();
    default:
      if (customSpeechRecognition) {
        customSpeechRecognition.start(
          (result: SpeechRecognitionResultOutput) => onResult(result.transcript),
          onError
        );
      }
  }
}

export function stopListening(deviceType: DeviceType) {
  switch (deviceType) {
    case DeviceType.Vuzix:
      return stopVuzixListening();
    case DeviceType.RealWear:
      return stopRealWearListening();
    default:
      if (customSpeechRecognition) {
        customSpeechRecognition.stop();
      }
  }
}

export function addVoiceCommand(
  deviceType: DeviceType, 
  command: string, 
  callback: () => void
) {
  switch (deviceType) {
    case DeviceType.Vuzix:
      return addVuzixCommand(command, callback);
    case DeviceType.RealWear:
      return addRealWearCommand(command, callback);
    default:
      // For custom speech recognition, we'll need to handle command recognition in the onResult callback
      console.log(`Custom voice command added: ${command}`);
  }
}