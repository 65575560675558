import React, { createContext, useContext, useEffect, useState } from 'react';
import { DeviceType } from '../utils/DeviceDetection';
import DeviceSpeechRecognition from '../utils/DeviceSpeechRecognition';

// Explicitly define the context value type
export interface SpeechContextType {
  startListening: () => Promise<void>;
  stopListening: () => Promise<void>;
  addCommand: (command: string, callback: () => void) => void;
  isListening: boolean;
  lastRecognizedText: string;
  error: Error | null;
  deviceType: DeviceType;
}

// Create context with explicit typing
export const SpeechRecognitionContext = createContext<SpeechContextType | null>(null);

// Custom hook with proper type checking
export const useSpeechRecognition = (): SpeechContextType => {
  const context = useContext(SpeechRecognitionContext);
  if (!context) {
    throw new Error('useSpeechRecognition must be used within a SpeechRecognitionProvider');
  }
  return context;
};

// Provider component with explicit props typing
interface SpeechRecognitionProviderProps {
  children: React.ReactNode;
  deviceType: DeviceType;
}

export const SpeechRecognitionProvider: React.FC<SpeechRecognitionProviderProps> = ({ 
  children, 
  deviceType 
}) => {
  const [isListening, setIsListening] = useState<boolean>(false);
  const [lastRecognizedText, setLastRecognizedText] = useState<string>('');
  const [error, setError] = useState<Error | null>(null);
  const [speechRecognition, setSpeechRecognition] = useState<DeviceSpeechRecognition | null>(null);

  useEffect(() => {
    const initializeSpeechRecognition = async () => {
      console.group('Speech Recognition Initialization');
      console.log('Initializing for device type:', deviceType);
      
      try {
        const recognition = new DeviceSpeechRecognition({
          deviceType,
          onResult: (text: string) => {
            setLastRecognizedText(text);
            console.log('Speech Recognition Result:', text);
          },
          onError: (error: Error) => {
            console.error('Speech Recognition Error:', error);
            setError(error);
          },
          onStart: () => {
            console.log('Speech Recognition Started');
            setIsListening(true);
            setError(null);
          },
          onEnd: () => {
            console.log('Speech Recognition Ended');
            setIsListening(false);
          }
        });

        await recognition.initialize();
        setSpeechRecognition(recognition);
        console.log('Speech Recognition Initialized Successfully');
      } catch (error) {
        console.error('Speech Recognition Initialization Failed:', error);
        setError(error as Error);
      } finally {
        console.groupEnd();
      }
    };

    initializeSpeechRecognition();

    // Cleanup on unmount
    return () => {
      if (speechRecognition) {
        speechRecognition.stop();
      }
    };
  }, [deviceType]);

  const startListening = async () => {
    try {
      console.log('Attempting to start listening');
      await speechRecognition?.start();
    } catch (error) {
      console.error('Error starting speech recognition:', error);
      setError(error as Error);
    }
  };

  const stopListening = async () => {
    try {
      console.log('Attempting to stop listening');
      await speechRecognition?.stop();
    } catch (error) {
      console.error('Error stopping speech recognition:', error);
      setError(error as Error);
    }
  };

  const addCommand = (command: string, callback: () => void) => {
    console.log('Adding voice command:', command);
    speechRecognition?.addCommand(command, callback);
  };

  // Explicitly typed value object
  const value: SpeechContextType = {
    startListening,
    stopListening,
    addCommand,
    isListening,
    lastRecognizedText,
    error,
    deviceType
  };

  return (
    <SpeechRecognitionContext.Provider value={value}>
      {children}
    </SpeechRecognitionContext.Provider>
  );
};

export default SpeechRecognitionContext;