import { useState, useCallback } from 'react';
import { LaunchCallUsers } from '../../../models/CallUsers';
import { useAuth } from '../../useAuth';
import axios from 'axios';

export function useAppointmentVideoCall() {
  const [callId, setCallId] = useState<string>('');
  const [callEnded, setCallEnded] = useState(false);
  const [callData, setCallData] = useState<LaunchCallUsers | null>(null);
  const [isCallStarting, setIsCallStarting] = useState(false);
  const [isErrorStarting, setIsErrorStarting] = useState(false);
  const [errorStarting, setErrorStarting] = useState<Error | null>(null);
  const [isSuccessStarting, setIsSuccessStarting] = useState(false);
  const [isTech] = useState(false);

  const startCall = useCallback(async (channelId: string): Promise<LaunchCallUsers> => {
    try {
      setIsCallStarting(true);
      setIsErrorStarting(false);
      setErrorStarting(null);
      
      // Replace with your actual API endpoint
      const response = await axios.post<LaunchCallUsers>('/api/calls/start', { channelId });
      const data = response.data;
      
      setCallData(data);
      setIsSuccessStarting(true);
      return data;
    } catch (error) {
      setIsErrorStarting(true);
      setErrorStarting(error as Error);
      throw error;
    } finally {
      setIsCallStarting(false);
    }
  }, []);

  const closeCall = useCallback(async (channelId: string): Promise<void> => {
    try {
      await axios.post('/api/calls/close', { channelId });
      setCallEnded(true);
    } catch (error) {
      console.error('Error closing call:', error);
      throw error;
    }
  }, []);

  return {
    callId,
    callEnded,
    callData,
    isCallStarting,
    isErrorStarting,
    errorStarting,
    isSuccessStarting,
    isTech,
    startCall,
    closeCall,
    setCallEnded,
  };
}

// Export the type of the hook's return value
export type UseAppointmentVideoCallResult = ReturnType<typeof useAppointmentVideoCall>;