import { useCallback, useRef, useState, useEffect } from 'react';
import { useToast } from '@chakra-ui/react';
import axios from 'axios';

interface AgoraTokenResponse {
  token: string | { token: string };
  uid: number;
  expiresIn?: number;
}

export const useAgoraToken = (
  channelName: string,
  autoRefresh: boolean = true
) => {
  const toast = useToast();
  const token = useRef<string>('');
  const refreshTimeout = useRef<NodeJS.Timeout | null>(null);
  const [uid, setUid] = useState<number | null>(null);
  const [tokenError, setTokenError] = useState<Error | null>(null);
  const [isTokenLoading, setIsTokenLoading] = useState<boolean>(false);

  const clearRefreshTimeout = useCallback(() => {
    if (refreshTimeout.current) {
      clearTimeout(refreshTimeout.current);
      refreshTimeout.current = null;
    }
  }, []);

  const setupTokenRefresh = useCallback((expiresIn: number) => {
    if (!autoRefresh) return;

    clearRefreshTimeout();

    // Refresh the token 30 seconds before it expires
    const refreshTime = Math.max((expiresIn - 30) * 1000, 5000); // At least 5 seconds
    refreshTimeout.current = setTimeout(async () => {
      try {
        await fetchToken();
      } catch (error) {
        console.error('Token refresh failed:', error);
        toast({
          title: 'Token Refresh Failed',
          description: 'Failed to refresh Agora token. Please try reconnecting.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }, refreshTime);
  }, [autoRefresh, clearRefreshTimeout]);

  const fetchToken = useCallback(async () => {
    if (!channelName) {
      console.warn('Cannot fetch token: Channel name is missing');
      return;
    }

    try {
      setIsTokenLoading(true);
      setTokenError(null);
      
      const baseUrl = import.meta.env.VITE_APP_API_HOST || '';
      const jwt = localStorage.getItem('jwt');
      
      if (!jwt) {
        throw new Error('No authentication token found');
      }
  
      const response = await axios.get<AgoraTokenResponse>(
        `${baseUrl}/api/v1/agora/token?channel=${encodeURIComponent(channelName)}`,
        {
          headers: {
            'Authorization': `Bearer ${jwt}`,
            'Accept': 'application/json'
          }
        }
      );

      console.log("Token response:", response.data);

      // Handle both formats of token response
      let tokenValue: string;
      let uidValue: number;

      // Check if response.data is an object
      if (response.data && typeof response.data === 'object') {
        // Extract token based on its type
        if (typeof response.data.token === 'object' && response.data.token && 'token' in response.data.token) {
          tokenValue = response.data.token.token;
        } else if (typeof response.data.token === 'string') {
          tokenValue = response.data.token;
        } else {
          throw new Error("Invalid token format received from backend");
        }

        // Extract UID
        if (typeof response.data.uid === 'number') {
          uidValue = response.data.uid;
        } else {
          throw new Error("Invalid UID format received from backend");
        }
      } else {
        throw new Error("Invalid response format from server");
      }

      if (!tokenValue || typeof uidValue !== 'number') {
        throw new Error("Invalid token or UID received from backend");
      }
  
      token.current = tokenValue;
      setUid(uidValue);
      
      console.log("Fetched Agora token:", tokenValue, "UID:", uidValue);
      
      // Set up token refresh if expiration info is available
      if (response.data.expiresIn) {
        setupTokenRefresh(response.data.expiresIn);
      }

      return {
        token: tokenValue,
        uid: uidValue
      };
    } catch (error) {
      console.error("Error fetching Agora token:", error);
      setTokenError(error instanceof Error ? error : new Error("Unknown error fetching token"));
      throw error;
    } finally {
      setIsTokenLoading(false);
    }
  }, [channelName, setupTokenRefresh]);

  useEffect(() => {
    // Initial token fetch if auto refresh is enabled
    if (autoRefresh && channelName) {
      fetchToken().catch(err => 
        console.error("Initial token fetch failed:", err)
      );
    }

    return () => {
      clearRefreshTimeout();
    };
  }, [autoRefresh, channelName, fetchToken, clearRefreshTimeout]);

  return {
    token,
    uid,
    fetchToken,
    tokenError,
    isTokenLoading
  };
};

export default useAgoraToken;