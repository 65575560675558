import { ProcessTeamUser, Process } from "../models/ProcessTeamUser";
import createHttpClient, { processFilters } from "../utils/httlClient";
import { GeneralTableFilters, LazyData } from "../types/AppTypes";
import _ from "lodash";

const client = createHttpClient();

const ProcessService = {
  getProcesses: async (enterpriseId: string, filters: GeneralTableFilters) => {
    const processedFilters = processFilters(filters)
    console.log('processedFilters', processedFilters);
    
    return await client.get<LazyData<ProcessTeamUser>>(
      `enterprise/${enterpriseId}/client/process/user-team?${processedFilters}`
    );
  },

  // Update return type to ProcessTeamUser
  getProcessesById: async (enterpriseId: string, pid: string) => {
    return await client.get<ProcessTeamUser>(
      `enterprise/${enterpriseId}/client/process/user-team/${pid}`
    );
  },

  getProcessAndExecuteById: async (enterpriseId: string, pid: string) => {
    return await client.get<ProcessTeamUser>(
      `enterprise/${enterpriseId}/client/process/user-team/${pid}/execute`
    );
  },

  processGoNext: async (enterpriseId: string, pid: string, step: number) => {
    return await client.get<ProcessTeamUser>(
      `enterprise/${enterpriseId}/client/process/user-team/${pid}/next-step/${step}`
    );
  },

  pauseProcess: async (enterpriseId: string, pid: string) => {
    return await client.post<void>(
      `enterprise/${enterpriseId}/client/process/user-team/${pid}/pause`
    );
  },

  cancelProcess: async (enterpriseId: string, pid: string) => {
    return await client.post<void>(
      `enterprise/${enterpriseId}/client/process/user-team/${pid}/cancel`
    );
  },

  getProcessesByIdUrl: (enterpriseId: string, pid: string) =>
    `${client.defaults.baseURL}/enterprise/${enterpriseId}/process/${pid}`,

  // Add method to get raw process if needed
  getRawProcessById: async (enterpriseId: string, pid: string) => {
    return await client.get<Process>(
      `enterprise/${enterpriseId}/process/${pid}`
    );
  },
};

export default ProcessService;